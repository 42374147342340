import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";
import Calendar_block from './Calendar_block';
import { IconContext } from 'react-icons' //IconContextをインポート
import { BsPlusLg } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

const Calendar = () => {
  const { timeLineList, selectedTimeline, setCalIsOpen, timeline, timeLineName } = useContext(ApiContext);
  const ecs = () => {
    setCalIsOpen(false);
  }
  return (
    <div>
      <div className='black_bg'>
        <div className='cal_box'>
          <div className='main'>
            <p>{timeLineName}</p>
            <Calendar_block />
          </div>

          <div className='btn_blue' id='maru_btn' onClick={ecs}>
            <IconContext.Provider value={{ color: '#FFF', size: '50px', className: 'shdow' }}>
              <IoClose />
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calendar