import React, { useState, useEffect, useReducer, useId, useInsertionEffect, useContext } from 'react'
import del from '../img/del.png';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useAnimation, motion } from "framer-motion";

import { IconContext } from 'react-icons' //IconContextをインポート
import { BsFillTrashFill } from "react-icons/bs";

const DelBox = () => {
  const controls = useAnimation()
  const startAnimation = () => {
    controls.start({
      scale: [0, 1], transition: { delay: 0, duration: 0.2, type: "easeOut" },
    })
  }
  const reset = () => {
    console.log("end")
  }
  useEffect(() => {
    startAnimation();
  }, []);
  return (

    <Droppable key="delArea" droppableId="delArea">
      {(provided) => (
        <motion.div className='delBox' id='delBox' animate={controls} onAnimationComplete={reset}>
          <div className='delArea' id='delArea' ref={provided.innerRef}
            {...provided.droppableProps}>
            <div className="delCircle">
              <IconContext.Provider value={{ color: '#FFF', size: '50px', className: 'shdow' }}>
                <BsFillTrashFill />
              </IconContext.Provider>
            </div>
            {provided.placeholder}
          </div>

        </motion.div>

      )}
    </Droppable>

  )
}

export default DelBox