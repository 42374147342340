import React, { useState, useEffect, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";

import { IconContext } from 'react-icons' //IconContextをインポート
import { MdOutlinePreview } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";

import { HiOutlineDocumentReport } from "react-icons/hi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { TbLogout } from "react-icons/tb";
//
//GrOverview



const SubMenu = () => {
  const { setAI_watchIsOpen, setAI_flowIsOpen, setAI_contentsIsOpen, subPreIsOpen, setWatchIsOpen } = useContext(ApiContext);
  const watch = () => {
    subPreIsOpen(false);
    setWatchIsOpen(true);
  }

  const ai_watch = () => {
    subPreIsOpen(false);
    setAI_watchIsOpen(true);
  }
  const ai_contents = () => {
    subPreIsOpen(false);
    setAI_contentsIsOpen(true);
  }

  const ai_folow = () => {
    subPreIsOpen(false);
    setAI_flowIsOpen(true);
  }


  return (
    <div className='sub_menu'>

      <div className='list' onClick={watch}>
        <div className='maru'>
          <IconContext.Provider value={{ color: '#FFF', size: '28px' }}><MdOutlinePreview /></IconContext.Provider>
        </div>
        <p>稼働監視</p>
        <IconContext.Provider value={{ color: '#333', size: '28px' }}><IoIosArrowForward /></IconContext.Provider>
      </div>


      <div className='list'>
        <div className='maru'>
          <IconContext.Provider value={{ color: '#FFF', size: '28px' }}><TbLogout /></IconContext.Provider>
        </div>
        <p>ログアウト</p>
        <IconContext.Provider value={{ color: '#333', size: '28px' }}><IoIosArrowForward /></IconContext.Provider>
      </div>
    </div>
  )
}

export default SubMenu