import React, { useState, useEffect, useContext, useRef } from 'react'
import img from '../img/file.jpg';
import { ApiContext } from "../context/ApiContext";
import ItemOption from './ItemOption';
import { IconContext } from 'react-icons' //IconContextをインポート
import { IoClose } from "react-icons/io5";
import { TbCloudUpload } from "react-icons/tb";
import { MdPreview } from "react-icons/md";
import mp3_file from '../img/mp3.png';
import { m } from 'framer-motion';

const ItemDetail = () => {

  const { detailIsOpen, setDetailIsOpen, timeline, setTimeline, groupFlg, gropuTimeLine, upDateTimeLine, statusChange, getTimeLine } = useContext(ApiContext);
  const [optionIsOpen, setOptionIsOpen] = useState(false);
  const { fileType, setFileType } = useContext(ApiContext);
  const { postItem } = useContext(ApiContext);
  const { thum, setThum } = useContext(ApiContext);
  const { thum_img, setThum_img } = useContext(ApiContext);
  const { name, setName } = useContext(ApiContext);
  const { time, setTime } = useContext(ApiContext);
  const { items, upDateFlg, setUpDateFlg, upDateItem, type_option, setType_option, item, getItems } = useContext(ApiContext);

  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleLoad = (e) => {

        let time = e.target.duration;

        //四捨五入
        time = Math.floor(time);
        setTime(time)
      };

      audio.addEventListener('canplaythrough', handleLoad);

      // クリーンアップ関数でイベントリスナーを削除
      return () => {
        audio.removeEventListener('canplaythrough', handleLoad);
      };
    }
  }, []);


  const sendTimeLine = (timeline_items) => {
    var timeLineTemp = "";
    for (let i = 0; i < timeline_items.length; i++) {
      timeLineTemp += (timeline_items[i].id);
      timeLineTemp += "-"
    }
    timeLineTemp = timeLineTemp.slice(0, -1);

    //console.log("groupFlg = " + groupFlg);
    if (groupFlg) {
      gropuTimeLine(timeLineTemp)
    } else {

      upDateTimeLine(timeLineTemp)
    }

  }

  const previewWindow = (e) => {
    if (fileType == 'mp3') {
      window.open(item, '_blank');
    } else {
      window.open(type_option, '_blank');
    }
  }

  const saveItem = () => {

    //サムネール作成
    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = mp3_file;

    image.onload = function () {
      // 画像をキャンバスに描画
      ctx.drawImage(image, 10, 0);

      // 画像の描画が完了した後にテキストを描画
      // 文字列を描画

      //ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
      let thum = canvas.toDataURL("image/png");

      setThum(thum);





      //


      if (upDateFlg) {
        upDateItem(thum);
        const timeline_items = Array.from(timeline);
        //timeline_items.push(" ")

        //getItems();
        setTimeline(timeline_items);
        sendTimeLine(timeline_items);
      } else {
        postItem(thum);
      }

      getTimeLine();
    }
  }

  const detailOff = () => {
    // setModalIsOpen(false);
    setDetailIsOpen(!detailIsOpen);

  }

  const optionOpen = () => {
    setOptionIsOpen(!optionIsOpen);
  }
  const createImg = (e) => {

    if (upDateFlg) {
      return;
    }


    const canvas = document.getElementById('canvas');
    const imgW = e.target.naturalWidth;
    const imgH = e.target.naturalHeight;
    let scale = 0;
    if (100 / 75 > imgW / imgH) {
      scale = 75 / imgH;
    } else {
      scale = 100 / imgW;
    }

    canvas.width = imgW * scale;
    canvas.height = imgH * scale;


    const ctx = canvas.getContext('2d');
    ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
    let thum = canvas.toDataURL("image/png").replace(new RegExp("data:image/png;base64,"), "");
    thum = "data:image/png;base64," + thum;

    setThum(thum);

  }

  const createMov = (e) => {

    if (upDateFlg) {
      return;
    }
    let time = e.target.duration;

    //四捨五入
    time = Math.round(time);
    setTime(time)
    //////////////////////////

    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    //文字列を描画
    ctx.font = "bold 20px 'ＭＳ ゴシック'";
    ctx.fillStyle = "rgba(0, 0, 0, 1)";
    ctx.fillText(time + "秒", 0, 20);

    //ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
    let thum = canvas.toDataURL("image/png");
    console.log(thum);
    setThum(thum);


  }

  return (
    <div>
      <canvas id="canvas" width="120" height="120"></canvas>
      <div className='black_bg'>
        <div className='white_bg'>
          <div className='btn_blue' id='maru_btn' onClick={detailOff}>
            <IconContext.Provider value={{ color: '#FFF', size: '50px', className: 'shdow' }}>
              <IoClose />
            </IconContext.Provider>
          </div>
          <div className='btn_nomal' id='send_btn_file' onClick={saveItem}>
            <IconContext.Provider value={{ color: '#FFF', size: '60px', className: 'shdow_upload' }}>
              <TbCloudUpload />
            </IconContext.Provider>
          </div>

          <input id="input" className="fileName" name="" type="text" value={name} placeholder="名称未定" onChange={(event) => setName(event.target.value)} />
          {
            (() => {

              return (

                <audio ref={audioRef} controls>
                  <source src={thum_img} type="audio/mpeg" />

                </audio>
              )




            })()
          }



          {(() => {
            if (fileType != "mp3") {

              return (
                <div className='timeBox'>
                  <input id="input" className="fileTime" name="" type="text" value={time} onChange={(event) => setTime(event.target.value)} />

                  <p>秒</p>
                </div>
              )
            } else {
              const timeStr = Math.floor(time) + "秒"
              return (
                <div className='timeBox'>
                  <p>{timeStr}</p>
                </div>
              )
            }

          })()}

          <p className='option' onClick={optionOpen}>{optionIsOpen ? '▼オプション' : '▲オプション'}</p>
          {optionIsOpen && <ItemOption />}


        </div>

      </div>
    </div>
  )
}

export default ItemDetail