import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from "chart.js";
  import { Pie } from "react-chartjs-2";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );
  
  
  
  


export const En_graph = (props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true,
        text: props.title
      }
    }
  };
  
  const data = {
    labels: props.labels,
    datasets: [
      {
        
        data: props.data,
        backgroundColor: props.color,
        borderWidth: 1,
      },
    ],
  };
  return (
    <div>
        <Pie options={options} data={data} />
        
    </div>

  )
}

export default En_graph