import React, { useState,useEffect,useReducer,useContext } from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { ApiContext } from "../context/ApiContext";
import TimelineHeader from './TimelineHeader';
import TimelineItem from './TimelineItem';
import Group from './Group';

export const TimeLine = () => {
  const {timeline ,setTimeline,sortTimeline,timeLineList,selectedTimeline,groupFlg, setGroupFlg} = useContext(ApiContext);
  useEffect(() => {

    sortTimeline();
  }, [timeLineList,selectedTimeline]);
  const [bgStyle, setBgStyle] = useState('timeline_nomal');
  let color = 'timeline_nomal'
 

  if(groupFlg){
    
    color = 'timeline_group'
   
  }else{
    color = 'timeline_nomal'
  
  }

  

  return (
    

  <Droppable key="timeline" droppableId="timeline" direction="horizontal">
   {(provided) => (
    <div className={'timeline'} id={color}>
        {groupFlg== false
          ?<TimelineHeader/>
          :<Group/>
        }
        <div className='timeline_main'>
        <div className='timeline_scroll'
         ref={provided.innerRef}
         {...provided.droppableProps}
        >
          
          {timeline.map((item_obj, index) => (
            
          <TimelineItem
            key ={index}
            index={index}
            item_obj={item_obj}
          />
      
        ))}


          {provided.placeholder}
        </div>
       
       </div>
    </div>
    )}
    </Droppable>

  )
}

export default TimeLine