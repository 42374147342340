import React, { createContext, useState, useEffect } from "react";
import { withCookies } from "react-cookie";
import axios from "axios";

export const HeaderContext = createContext();

const HeaderContextProvider = (props) => {
    const [scrollFlg, setScrollFlg] = useState(false);
    return (

        <HeaderContext.Provider
            value={{
                scrollFlg,
                setScrollFlg,
            }}

        >
            {props.children}
        </HeaderContext.Provider>


    )


}

export default withCookies(HeaderContextProvider);