import React, { useState,useEffect,useContext } from 'react'
import { ApiContext } from "../context/ApiContext";
import { IconContext } from 'react-icons' //IconContextをインポート
import { IoClose } from  "react-icons/io5";
import { BiCloudDownload } from "react-icons/bi";
import Heatmap from  "../graph/Heatmap";
import map1 from '../img/map/map1.png';
import map2 from '../img/map/map2.png';
import map3 from '../img/map/map3.png';
import { Bar_graph } from "../graph/Bar_graph";
import { Line_graph } from "../graph/Line_graph";
import { En_graph } from "../graph/En_graph";


export const HM = () => {
  return  <Heatmap width ={800} height ={600}/>
};






const AI_flow = () => {

    

    const {setAI_flowIsOpen,timeLineList,heatMap_img, setHeatMap_img} = useContext(ApiContext);
    const [targetDay, setTargetDay] = useState('');
    const [targetTime,setTargetTime] = useState('');
    const [targetDay2, setTargetDay2] = useState('');
    const [targetTime2,setTargetTime2] = useState('');
    const [selectStb, setSelectStb] = useState(0);
    const [selectMap, setSelectMap] = useState(map1);
    const changeTimeline = (event) => {
      console.log(event.target.value)
      setSelectStb(event.target.value)
      if(event.target.value == 1){
        setSelectMap(map1)
      }else if(event.target.value == 2){
        setSelectMap(map2)
      }else if(event.target.value == 3){
        setSelectMap(map3)
      }
      setHeatMap_img(event.target.value)
      //changeTimeLine(event.target.value);
    }




    const now_csv =(e)=>{
        const cal_day1 = document.getElementById('cal_day1').value;
        const cal_day2 = document.getElementById('cal_day2').value;
        const cal_time1 = document.getElementById('cal_time1').value;
        const cal_time2 = document.getElementById('cal_time2').value;
        const cal_day1_f = cal_day1.replace(/-/g, '/')
        const cal_day2_f = cal_day2.replace(/-/g, '/')
        const from = '?from_day='+cal_day1_f +' '+ cal_time1
        const to = '&to_day='+cal_day2_f +' '+ cal_time2
        const str = from + to;
        //set_csv(str)
  
      }


    const now_data =()=>{
      
        let cal_day1 = document.getElementById('cal_day1').value;
        let cal_day2 = document.getElementById('cal_day2').value;
        const cal_time1 = document.getElementById('cal_time1').value;
        const cal_time2 = document.getElementById('cal_time2').value;
    }
    const ecs = () =>{
        setAI_flowIsOpen(false);
    }
    const labelsA = ['男性(%)', '女性(%)']
    const dataA = [12, 19]
    const colorA = ['rgba(54, 162, 235, 0.2)','rgba(255, 99, 132, 0.2)']


    const labelsB = ['0〜9歳', '10代', '20代', '30代', '40代', '50代' , '60代' , '70歳〜']
    const dataB = [12, 19, 3, 5, 2, 3,4,5]
    const colorB= [
      'rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 159, 64, 0.2)',
    ]


  return (
    <div>

    <div className='black_bg'>
    <div className='cal_box'>
    <div className='Calendar_block'>

    </div>
    <div className='btn_blue' id='maru_btn' onClick={ecs}>
          <IconContext.Provider value={{ color: '#FFF', size: '50px',className:'shdow' }}>
              <IoClose />
            </IconContext.Provider>
          </div>
          <div className='cal_form'>
          <input className ="cal_day" id="cal_day1" type="date" name="example1" defaultValue={targetDay}></input>
            
            <p>〜</p>
            <input className ="cal_day" id="cal_day2" type="date" name="example1" defaultValue={targetDay2}></input>

            <select value={selectStb} className='timeline_name' name="timeline_name" onChange={(e) => changeTimeline(e) }>
              <option key ={0}value={1}>
                エリアA
              </option>
              <option key ={1}value={2}>
                エリアB
              </option>
              <option key ={2}value={3}>
                エリアC
              </option>
        
            </select>

            
            <button className='btn_blue' id='setting' onClick={now_data}>設定</button>
            <button className='btn_blue' id='setting2' onClick={now_csv}>
            <IconContext.Provider value={{ color: '#FFF', size: '40px',className:'shdow' }}>
        <BiCloudDownload />
       </IconContext.Provider>
            </button>
      </div>
      <div className='tab_box'>
      
          <div className='heat_box'>
          <div className='heat_map'>
            <img id="heat_target" src={selectMap} alt="" />

            <Heatmap width ={800} height ={600}/>
            </div>
          </div>
          <div className="bar"><Bar_graph title={"時間帯別　(人/時間)"} /></div>
          
          <div className='row'>
            <div className="en"><En_graph title={"男女比率"} labels={labelsA} data={dataA} color={colorA}/></div>
            <div className="en"><En_graph title={"年齢比率"} labels={labelsB} data={dataB} color={colorB}/></div>
          
          </div>
        </div>

       

          <div>
          

          </div>

    </div>
    
    
    </div>
    </div>
  )
}

export default AI_flow