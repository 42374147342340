import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";
import { useAnimation, motion } from "framer-motion";
import { IconContext } from 'react-icons' //IconContextをインポート
import { FaInfoCircle } from "react-icons/fa";
import { BiLike } from "react-icons/bi";
const Toast = (props) => {
    const controls = useAnimation()
    const [contents, setContents] = useState('');
    const { errFlg, setErrFlg } = useContext(ApiContext);
    let animation = true;











    const end = () => {


        controls.start({
            opacity: [0, 1, 1, 0], transition: { delay: 0, duration: 3, time: [0, 0.1, 1, 0.1], type: "easeOut" },

        })
        //alert("#")
        /*
        controls.start({
            opacity: [1, 0], transition: { delay: 1, duration: 0.4, type: "easeOut" },
        })
        */



    }
    const reset = () => {

        setErrFlg(false)
    }

    useEffect(() => {

        end();



        //animation = true;
        //end()
    }, [errFlg]);

    return (
        <div className='toastBox'>

            <motion.div className='block' animate={controls} onAnimationComplete={reset}>
                <div className='toast_set'>
                    <IconContext.Provider value={{ color: '#DDD', size: '25px' }}>
                        {(() => {
                            if (props.icon == "warning") {
                                return (<FaInfoCircle />)
                            } else {
                                return (< BiLike />)
                            }
                        })()}
                    </IconContext.Provider>
                    <p>{props.massage}</p>
                </div>
            </motion.div>
        </div>
    )
}

export default Toast