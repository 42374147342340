import React, { useState,useEffect,useContext} from 'react'
import { ApiContext } from "../context/ApiContext";
import { IconContext } from 'react-icons' //IconContextをインポート
import { RiLoader2Line } from "react-icons/ri";

const Loading = () => {
const {loading, setLoading} = useContext(ApiContext);
  return (
    <div>
    {
    (() => {


        if(loading){
        return(
        <div className='black_bg'>
            <IconContext.Provider value={{ color: '#666', size: '60px',className:'loading_icon' }}>
                    <RiLoader2Line />
            </IconContext.Provider>

        </div>
        )
        }
        
    })()
    }
    </div>
  )
}

export default Loading