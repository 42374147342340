import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";




import send from '../img/send.png';


import { IconContext } from 'react-icons' //IconContextをインポート
import { FaCalendarAlt } from "react-icons/fa";
import { BsPlayCircleFill } from "react-icons/bs";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoWarning } from "react-icons/io5";
import { MdDownloading } from "react-icons/md";
import { FaPauseCircle } from "react-icons/fa";

import Toast from './Toast';
//FaCalendarAlt

const TimelineHeader = () => {
  const { items, timeLineList, changeTimeLine, selectedTimeline, setSelectedTimeline, calIsOpen, setCalIsOpen, preIsOpen, setPreIsOpen, getTimeLineList_all, targetDay, setTargetDay, targetTime, setTargetTime, getTimeLine, setTimeLineName, statusChange } = useContext(ApiContext);
  const [icon, setIcon] = useState();
  const [playFlg, setPlayFlg] = useState(false);
  const { errFlg, setErrFlg } = useContext(ApiContext);
  const changeTimeline = (event) => {

    setTimeLineName(timeLineList[event.target.value].name)
    //setSelectedTimeline(event.target.value);
    setST(timeLineList[event.target.value].status)
    //
    changeTimeLine(event.target.value);
  }
  const openCal = () => {
    //const now = new Date();
    getTimeLineList_all();

    setCalIsOpen(!calIsOpen);
  }
  const openPre = () => {
    setPlayFlg(!playFlg);
    const preSound = document.getElementById('preSound');
    if (playFlg) {
      preSound.pause();
      return;
    }
    let count = 0
    const playFile = timeLineList[selectedTimeline].timeline;
    console.log(playFile)
    const playList = playFile.split('-');
    console.log(playList)
    console.log(items)
    let playListFile = [];
    for (let i = 0; i < playList.length; i++) {
      for (let j = 0; j < items.length; j++) {
        if (playList[i] == items[j].id) {
          //console.log(items[j].file)
          playListFile.push(items[j].file);
        }

      }
    }
    //playListFileのmp3を再生

    preSound.src = playListFile[0];

    preSound.play();
    //おわったら次のファイルを再生
    preSound.onended = function () {
      count++;
      if (count >= playListFile.length) {
        count = 0;
      }

      preSound.src = playListFile[count];
      preSound.play();

    }


    /*
    const now = new Date();
    const y = now.getFullYear();
    const m = ('00' + (now.getMonth() + 1)).slice(-2);
    const d = ('00' + now.getDate()).slice(-2);
    const h = ('00' + now.getHours()).slice(-2);
    const min = ('00' + now.getMinutes()).slice(-2);
    setTargetDay(y + '-' + m + '-' + d);
    setTargetTime(h + ':' + min);
    getTimeLineList_all(now);
    setPreIsOpen(!preIsOpen);
    */
  }

  //



  const sendSTB = () => {
    setErrFlg(true);
    statusChange('配信中');
    getTimeLineList_all(null, true);

  }

  const toast = () => {

    const msg = timeLineList[selectedTimeline].name + 'に配信しました';
    return (<Toast icon="info" massage={msg} />);
  }

  const setST = (st) => {
    if (st == '未配信') {
      setIcon(<IoWarning />)
    } else if (st == '配信中') {
      setIcon(<MdDownloading />)
    }


  }

  useEffect(() => {
    getTimeLine();
    /*
    console.log(timeLineList)
    if (timeLineList.length != 0) {
      setTimeLineName(timeLineList[0].name)
      setST(timeLineList[0].status)
    }
    */



  }, []);


  useEffect(() => {
    setTimeLineName(timeLineList[0].name)
    setST(timeLineList[0].status)
  }, [timeLineList]);



  return (
    <div className="timeline_box">
      <div className='pre_sound'>
        <audio id="preSound" src="" controls>
          お使いのブラウザはオーディオ要素をサポートしていません。
        </audio>
      </div>
      <select value={selectedTimeline} className='timeline_name' name="timeline_name" onChange={(e) => changeTimeline(e)}>
        {timeLineList.map((item_obj, index) => (
          <option
            key={index}
            value={index}
          >
            {item_obj.name}
          </option>
        ))}
      </select>
      <button className="btn" id="func_btn" onClick={openCal}>
        <IconContext.Provider value={{ color: '#1384bf', size: '24px' }}><FaCalendarAlt /></IconContext.Provider>
      </button>
      <button className="btn" id="func_btn" onClick={openPre}>
        {!playFlg && <IconContext.Provider value={{ color: '#1384bf', size: '24px' }}><BsPlayCircleFill /></IconContext.Provider>}
        {playFlg && <IconContext.Provider value={{ color: '#1384bf', size: '24px' }}><FaPauseCircle /></IconContext.Provider>}
      </button>
      <div className='right'>

        {/*
        <div id='timeline_status'>
          <IconContext.Provider value={{ color: '#8c9db4', size: '25px', className: 'shdow_in' }}>{icon}</IconContext.Provider>
          <p>
            {timeLineList[selectedTimeline].status}
          </p>
        </div>
        */}


        <div className="send_btn" onClick={sendSTB}>
          <IconContext.Provider value={{ color: '#FFF', size: '50px', className: 'shdow_add' }}>
            <AiOutlineCloudUpload />
          </IconContext.Provider>
        </div>

      </div>
      {errFlg && toast()}
    </div>
  )
}

export default TimelineHeader