import React, { useState, useEffect, useContext } from 'react'
import { ApiContext } from "../context/ApiContext";

import Folder from '../app/Folder'
import app1 from '../img/app/folder.png';
import Oekaki from '../app/Oekaki'
import Web from '../app/Web'

const AppBox = () => {
  const { userID, getApp, app, project, setApp, app_admin, setApp_admin, modalIsOpen, setModalIsOpen, appMode, setAppMode, setDetailIsOpen, setAppContents, setLoading, getItems } = useContext(ApiContext);
  const [appList, setAppList] = useState([]);
  const [app_adminList, setApp_adminList] = useState([]);
  useEffect(() => {



    let app_tmp = []
    for (let i = 0; i < app.length; i++) {
      for (let j = 0; j < project.app.length; j++) {
        if (app[i].id == project.app[j]) {
          app_tmp.push(app[i]);
        }

      }

    }
    setAppList(app_tmp);

    let app_admin_tmp = []
    for (let i = 0; i < app_admin.length; i++) {
      for (let j = 0; j < project.app_admin.length; j++) {
        if (app_admin[i].id == project.app_admin[j]) {
          app_admin_tmp.push(app_admin[i]);
        }

      }

    }
    setApp_adminList(app_admin_tmp);



  }, []);

  window.addEventListener('message', function (e) {
    switch (e.data.action) {
      case 'save':
        getItems();
        setModalIsOpen(!modalIsOpen);
        //alert(e.data.message);
        break;
    }
  });
  const openMenu = (app) => {

    console.log(app.setting_type);
    if (app.setting_type == 'link') {

      const url = 'http://localhost:8888/talk/'

      window.open(url, null, 'top=100,left=100,width=1000,height=800');
    } else {
      setAppContents(app);
      setAppMode(true);
    }


  }

  return (
    <div className="app_box">
      <div className="app_area">

        <div className="app_scroll">
          {app_adminList.map((app_obj, index) => (
            <div key={index} className={'app_main'}>
              <div className="app_icon" onClick={() => openMenu(app_obj)}>
                <img src={app_obj.thum} alt="" />
              </div>
              <div className="app_txt">
                <p>{app_obj.appName}</p>
              </div>
            </div>

          ))}





        </div>

      </div>

    </div>
  )
}

export default AppBox