import React, { createContext, useState, useEffect } from "react";
import { withCookies } from "react-cookie";
import axios from "axios";

export const ApiContext = createContext();

const ApiContextProvider = (props) => {


  const token = props.cookies.get("jwt-token");
  const baseURL = "https://aivos-sv.com/ds_project/api/v1/"
  const [userID, setUserID] = useState(0);
  const [projectNum, setProjectNum] = useState(0);
  const [project, setProject] = useState([]);
  const [items, setItems] = useState([]);
  const [item_temp, setItem_temp] = useState([]);
  const [app, setApp] = useState([]);
  const [app_admin, setApp_admin] = useState([]);
  const [appMode, setAppMode] = useState(true);
  const [appContents, setAppContents] = useState([]);
  const [timeLineName, setTimeLineName] = useState([]);
  const [watchIsOpen, setWatchIsOpen] = useState(false);
  const [serchMode, setSerchMode] = useState(false);
  const [errFlg, setErrFlg] = useState(false);
  const [delFlg, setDelFlg] = useState(false);
  //
  const [name, setName] = useState("");
  const [time, setTime] = useState(0);
  const [item, setItem] = useState(null);
  const [thum, setThum] = useState(null);
  const [type_option, setType_option] = useState("");
  const [span_start, setSpan_start] = useState("");
  const [span_end, setSpan_end] = useState("");
  const [interval_start, setInterval_start] = useState("");
  const [interval_end, setInterval_end] = useState("");
  const [play_type, setPlay_type] = useState("nomal");

  const [thum_img, setThum_img] = useState(null);
  const [fileType, setFileType] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);

  const [timeline, setTimeline] = useState([]);
  const [selectedTimeline, setSelectedTimeline] = useState(0);
  const [timeLineList, setTimeLineList] = useState([]);
  const [targetDay, setTargetDay] = useState('');
  const [targetTime, setTargetTime] = useState('');

  const [paramList, setParamList] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailIsOpen, setDetailIsOpen] = useState(false);
  const [calIsOpen, setCalIsOpen] = useState(false);
  const [preIsOpen, setPreIsOpen] = useState(false);
  const [subIsOpen, subPreIsOpen] = useState(false);
  const [upDateFlg, setUpDateFlg] = useState(false);

  const [groupFlg, setGroupFlg] = useState(false);
  const [allTimelineList, setAllTimelineList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [AI_watchIsOpen, setAI_watchIsOpen] = useState(false);
  const [AI_flowIsOpen, setAI_flowIsOpen] = useState(false);
  const [AI_contentsIsOpen, setAI_contentsIsOpen] = useState(false);
  const [heatMap_img, setHeatMap_img] = useState(0);




  const getProject = async (num) => {
    try {
      const res = await axios.get('https://aivos-sv.com/ds_project/user/project/' + num + '/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });


      setProject(res.data)

    } catch {
      console.log("error");
    }


    //setProject

  }


  const getApp_admin = async () => {
    try {
      const res = await axios.get('https://aivos-sv.com/ds_project/user/app_admin/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(res.data)
      setApp_admin(res.data)
    } catch {
      console.log("error");
    }

  }

  const getApp = async () => {
    try {
      const res = await axios.get('https://aivos-sv.com/ds_project/user/app/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      setApp(res.data);
      getApp_admin();
    } catch {
      console.log("error");
    }

  }
  const getParam = async (param) => {
    let list = []
    for (let l = 0; l < param.length; l++) {
      const num = param[l];
      try {
        const res = await axios.get('https://aivos-sv.com/ds_project/user/param/' + num + '/', {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        list.push(res.data);
        console.log("PUSH")
      } catch {
        console.log("error");
      }

    }
    setParamList(list);


  }
  const getItems = async () => {

    try {
      const res = await axios.get(baseURL, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      let tempList = [];
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].type.split('-').length == 1) {
          tempList.push(res.data[i])
        }
      }


      setItems(tempList);
      getTimeLine();
    } catch {

      console.log("error");
    }
  }
  const serchItems = async (type) => {

    try {
      const res = await axios.get(baseURL, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      let tempList = [];
      for (let i = 0; i < res.data.length; i++) {

        if (res.data[i].type == type) {
          tempList.push(res.data[i])
        }

      }
      setItem_temp(tempList);

      //getTimeLine();
    } catch {

      console.log("error");
    }
  }

  const serchKey = async (word) => {

    try {
      const res = await axios.get(baseURL, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      let tempList = [];
      for (let i = 0; i < res.data.length; i++) {

        if (res.data[i].name.indexOf(word) == 0) {
          tempList.push(res.data[i])
        }

      }
      setItem_temp(tempList);
      //getTimeLine();
    } catch {

      console.log("error");
    }
  }
  const delContents = async (num) => {

    try {
      const res = await axios.delete(baseURL + num + '/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      getItems();
      //setItems(res.data);
      //getTimeLine();

      //setItems(res.data);
      //getTimeLine();
      //setItems(res.data);
      //getTimeLine();

    } catch {

      console.log("error");
    }

  }


  //コンテンツの名前,再生時間等の詳細
  const getContents = async (num) => {
    try {
      const res = await axios.get(baseURL + num + '/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log("------------")
      console.log(res.data)
      setTime(res.data.time);
      setFileType(res.data.type);
      setType_option(res.data.type_option);
      setName(res.data.name);
      setThum_img(res.data.file);
      setSpan_start(res.data.span_start);
      setSpan_end(res.data.span_end);
      setInterval_start(res.data.interval_start);
      setInterval_end(res.data.interval_end);
      setPlay_type(res.data.play_type);

    } catch {

      console.log("error");
    }
  }


  useEffect(() => {

    if (token != undefined) {

      getUser();

    }

  }, [token]);

  const getUser = async () => {
    try {
      const res = await axios.get('https://aivos-sv.com/ds_project/user/users_me/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setUserID(res.data[0].id);
      props.cookies.set("userID", res.data[0].id);

      setProjectNum(res.data[0].projectNum);
      //console.log(res.data[0])
      console.log(res)
      getProject(res.data[0].projectNum);


      getItems()

      getApp();

    } catch {

      console.log("error");
    }
  };

  const statusChange = async (status) => {
    //timeline_detail


    try {
      const uploadData = new FormData();
      uploadData.append("status", status);
      uploadData.append("author", items[0].author);
      const res = await axios.patch(
        baseURL + "timeline_detail/" + timeLineList[selectedTimeline].id + "/",
        uploadData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      getTimeLine();
    } catch {
      console.log("error");
    }
  };



  //保存タイムライン読み込み
  const getTimeLine = async () => {
    //!!!!!!!!!!!!!!!!!!!!!!!

    try {
      const res = await axios.get(baseURL + "timeline/" + projectNum + "/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });


      setTimeLineList(res.data)


    } catch {

      console.log("error");
    }
  };
  const changeTimeLine = (num) => {
    setSelectedTimeline(num);
    //let timelineNum = 1;
    //sortTimeline(timeLineList,items,timelineNum)

  }
  const upDateItem = async (thum_img) => {

    try {
      const uploadData = new FormData();
      uploadData.append("author", userID);
      uploadData.append("name", name);
      uploadData.append("time", time);
      uploadData.append("span_start", span_start);
      uploadData.append("span_end", span_end);
      uploadData.append("interval_start", interval_start);
      uploadData.append("interval_end", interval_end);
      uploadData.append("play_type", play_type);
      uploadData.append("type_option", type_option);

      //uploadData.append("thum", thum_img);
      //console.log(thum_img)

      const res = await axios.patch(

        baseURL + selectedItem + '/',
        uploadData,
        {
          headers: {
            "content-type": "multipart/form-data" ,
            Authorization: `Token ${token}`,
          },

        }


      );
      //バグがあるかも///

      getItems();
      setGroupFlg(false);
      setModalIsOpen(false);
      setDetailIsOpen(false);



    } catch (error) {
      // ↓ エラー時のレスポンスデータ
      const errorData = error.response.data;
      console.log(error);
    }
  };

  const postItem = async (thum_img) => {

    console.log("QQQQQQ-----------------------------------")
    console.log(items)

    try {
      const uploadData = new FormData();
      //↓バグあり!!!!!!!!!!!!!!!!!!///////////////////
      console.log(userID)
      console.log(projectNum)
      uploadData.append("author", userID);

      uploadData.append("name", name);
      if (fileType != "mov") {
        uploadData.append("time", time);
      }

      uploadData.append("span_start", span_start);
      uploadData.append("span_end", span_end);
      uploadData.append("interval_start", interval_start);
      uploadData.append("interval_end", interval_end);
      uploadData.append("play_type", play_type);
      uploadData.append("type_option", type_option);

      if (item != null) {
        uploadData.append("file", item);
      }

      uploadData.append("thum", thum_img);
      console.log(thum_img)
      uploadData.append("type", fileType);

      let url = baseURL


      const res = await axios.post(
        url,
        uploadData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },

        }


      );
      getItems();
      setLoading(false);
      setThum(null);
      setModalIsOpen(false);
      setDetailIsOpen(false);

    } catch (error) {
      // ↓ エラー時のレスポンスデータ
      const errorData = error.response.data;
      console.log(error);
    }
  };

  //////////グループ周り//////////////////////////
  const gropuTimeLine = async (timeLineStr) => {
    setType_option(timeLineStr)
    sortTimeline_group(timeLineStr)
  };

  const sortTimeline_group = (timeLineStr) => {
    const timeLine = timeLineStr.split('-');
    const timeLineList_temp = new Array();
    for (let i = 0; i < timeLine.length; i++) {
      for (let j = 0; j < items.length; j++) {
        if (items[j].id == timeLine[i]) {
          var obj = new Object();
          obj.id = items[j].id;
          obj.thum = items[j].thum;
          obj.file = items[j].file;
          obj.name = items[j].name;
          obj.type = items[j].type;
          obj.type_option = items[j].type_option;
          obj.span_start = items[j].span_start;
          obj.span_end = items[j].span_end;
          obj.interval_start = items[j].interval_start;
          obj.interval_end = items[j].interval_end;
          obj.play_type = items[j].play_type;

          timeLineList_temp.push(obj);

        }
      }
    }



    //timeLineList_temp = timeLineList_temp.reverse();
    setTimeline(timeLineList_temp)
  }


  /////////タイムラインまわり///////////////////////////
  const upDateTimeLine = async (timeLineStr) => {
    try {
      const uploadData = new FormData();
      uploadData.append("timeline", timeLineStr);
      uploadData.append("author", items[0].author);
      const res = await axios.patch(
        baseURL + `timeline_detail/${timeLineList[selectedTimeline].id}/`,
        uploadData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      getTimeLine();
    } catch {
      console.log("error");
    }
  };





  const sortTimeline = () => {



    if (timeLineList.length == 0) {
      return
    }


    const timeLineTarget = timeLineList[selectedTimeline].timeline;




    const timeLineList_temp = getTimeLine_to_object(timeLineTarget)






    //timeLineList_temp = timeLineList_temp.reverse();
    setTimeline(timeLineList_temp)
  }
  //タイムライン番号からオブジェクト変換
  const getTimeLine_to_object = (timeLineTarget) => {
    const str = timeLineTarget
    const timeLineList_temp = new Array();
    for (let i = 0; i < str.split('-').length; i++) {
      for (let j = 0; j < items.length; j++) {
        if (items[j].id == str.split('-')[i]) {

          var obj = new Object();
          obj.id = items[j].id;
          obj.thum = items[j].thum;
          obj.file = items[j].file;
          obj.name = items[j].name;
          obj.type = items[j].type;
          obj.time = items[j].time;
          obj.type_option = items[j].type_option;
          obj.span_start = items[j].span_start;
          obj.span_end = items[j].span_end;
          obj.interval_start = items[j].interval_start;
          obj.interval_end = items[j].interval_end;
          obj.play_type = items[j].play_type;

          timeLineList_temp.push(obj);

        }
      }
    }
    return (timeLineList_temp);
  }

  //
  const format_TimeList = (list) => {

    let formatList = [];

    for (let i = 1; i < list.length - 1; i++) {

      let start = list[i];
      let end = list[i + 1];

      if (start != "00:00") {
        let date = new Date(2022, 0, 1, start.split(':')[0], start.split(':')[1])
        date.setMinutes(date.getMinutes() + 1)
        const h = ('00' + (date.getHours())).slice(-2);
        const m = ('00' + date.getMinutes()).slice(-2);
        start = h + ":" + m;

      }



      const str = start + '~' + end;
      formatList.push(str)
    }




    return formatList


  }

  ///////////////////////////////////////////////////////////////////////////

  ///////////////////////////フォルダの中身をフラット化///////////////////////////
  const flatItem = () => {
    let flatList = [];
    for (let i = 0; i < timeline.length; i++) {
      if (timeline[i].type == 'group') {
        const groupList = getTimeLine_to_object(timeline[i].type_option)
        for (let j = 0; j < groupList.length; j++) {
          flatList.push(groupList[j]);
        }
      } else {
        flatList.push(timeline[i])
      }

    }
    return flatList;
  }


  //タイムライン取得
  ///////////////////////////////////////////////////////////////////////////
  const getTimeLineList_all = (now_time = null, sendFlg = false) => {

    let flatList = flatItem();
    let now = new Date();

    
    let obj_parent = [];
    let interval_list = [];

    let interval_start_temp = "00:00"
    let interval_end_temp = "23:59";

    let max = 8;
    if (now_time != null) {
      now = now_time
      max = 1;
    }


    for (let i = 0; i < max; i++) {
      const dayFormat = formatDate(now)
      let timeList = ['', '00:00', '23:59'];

      //時間帯List作る////////////////////////////
      if (now_time == null) {
        for (let j = 0; j < flatList.length; j++) {

          const spanFlg = spanChk(now, flatList[j].span_start, flatList[j].span_end);
          if (spanFlg == false) {
            break;
          }
          if (interval_start_temp != flatList[j].interval_start || interval_end_temp != flatList[j].interval_end) {
            interval_start_temp = flatList[j].interval_start;
            interval_end_temp = flatList[j].interval_end;
            timeList.push(interval_start_temp);
            timeList.push(interval_end_temp);
          }

        }

        // 重複を削除したリスト
        timeList = [...new Set(timeList)]
        timeList.sort();
        timeList = format_TimeList(timeList)
      } else {

        const h = ('00' + now.getHours()).slice(-2);
        const m = ('00' + now.getMinutes()).slice(-2);
        //timeList = ['03:00']
        timeList = [h + ':' + m];


      }




      let saveDay = "";
      for (let k = 0; k < timeList.length; k++) {
        let timeFlg_list = [];
        let allFlg_list = [];
        let obj = [];
        let obj2 = [];
        
        obj.day = dayFormat + " " + timeList[k];
        let timeContents_list = [];
        console.log(obj.day)
        //alert(obj.day)

        for (let j = 0; j < flatList.length; j++) {
          let item = []
          const spanFlg = spanChk(now, flatList[j].span_start, flatList[j].span_end);

          //時間帯////////////////////////////
          if (spanFlg) {
            if (flatList[j].play_type == 'all') {
              allFlg_list.push(flatList[j]);
            }
            const intervalFlg = intervalChk(timeList[k], flatList[j].interval_start, flatList[j].interval_end);
            if (intervalFlg) {

              if (flatList[j].play_type == 'time') {
                timeFlg_list.push(flatList[j]);
              } else {
                item.push(flatList[j]);
                obj.push(item);
              }

            }


          }
        }

        if (timeFlg_list.length > 0) {
          const tempDay = obj.day;
          obj = [];

          obj.day = tempDay;
          //timeFlg_list
          for (let l = 0; l < timeFlg_list.length; l++) {
            let item2 = []


            item2.push(timeFlg_list[l]);
            obj.push(item2);
          }


        }

        if (allFlg_list.length > 0) {

          const tempDay = obj.day.split(' ')[0];
          if (tempDay != saveDay) {
            saveDay = tempDay
            console.log("=============")
            console.log(saveDay);


            obj2.day = tempDay;
            //timeFlg_list
            for (let l = 0; l < allFlg_list.length; l++) {

              let item2 = [];
              item2.push(allFlg_list[l]);
              obj2.push(item2);

            }
          }

        }




        if (allFlg_list.length == 0) {
          obj_parent.push(obj)
        } else {
          if (obj2.length > 0) {
            obj_parent.push(obj2)
          }


        }


      }


      obj_parent = sameItem(obj_parent);


      now.setDate(now.getDate() + 1);

    }
    if (sendFlg) {

      copyFiles(obj_parent, project.name, selectedTimeline);
    } else {
      setAllTimelineList(obj_parent);
    }



  }
  const copyFiles = async (obj_parent, project_name, selectedTimeline) => {
   
    setLoading(true);
    try {
      const uploadData = new FormData();

      uploadData.append("obj_parent", JSON.stringify(obj_parent));
      uploadData.append("userID", userID);
      uploadData.append("selectedTimeline", timeLineList[selectedTimeline].id);

      const url = 'https://aivos-sv.com/ds_project/api/copyFiles/'


      const res = await axios.post(
        url,
        uploadData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },

        }


      );

      setLoading(false);


    } catch (error) {
      // ↓ エラー時のレスポンスデータ
      const errorData = error.response.data;
      console.log(error);
    }
  };



  const intervalChk = (now, interval_start, interval_end) => {
    let flg = false;
    if (interval_start == "") {
      interval_start = '00:00'
    }
    if (interval_end == "") {
      interval_end = '23:59'
    }
    if (now <= interval_end && now >= interval_start) {
      flg = true;
    }

    return flg;
  }

  const spanChk = (now, span_start, span_end) => {
    let flg = false;

    let start = new Date('1900-1-1');
    if (span_start != "") {
      start = new Date(span_start);
    }
    let end = new Date('3000-1-1');
    if (span_end != "") {
      end = new Date(span_end);
      end.setDate(end.getDate() + 1);
    }

    if (now <= end && now >= start) {
      flg = true;
    }



    return flg;


  }

  //同一のitemをまとめる
  const sameItem = (obj_parent) => {

    let obj = [];
    let tmpListID = [];
    let sameFlg = false;
    for (let i = 0; i < obj_parent.length; i++) {
      let tmpDay = obj_parent[i].day;

      for (let j = 0; j < obj_parent[i].length; j++) {
        console.log(tmpListID);
        if (tmpListID != obj_parent[i][j][0].id) {
          tmpListID = obj_parent[i][j][0].id
          sameFlg = false;
        } else {
          sameFlg = true;
        }
      }
      if (sameFlg == false) {
        obj.push(obj_parent[i]);
      } else {
        obj_parent[i - 1].day = obj_parent[i - 1].day.split('~')[0] + '~' + obj_parent[i].day.split('~')[1];
      }

    }
    return obj

  }


  //日付整形
  const formatDate = (dt) => {
    const y = dt.getFullYear();
    const m = ('00' + (dt.getMonth() + 1)).slice(-2);
    const d = ('00' + dt.getDate()).slice(-2);
    const w = dt.getDay();
    const WeekList = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return (y + '-' + m + '-' + d + '[' + WeekList[w] + ']');
  }

  ///////////////////////////////////////////////////////////////////////////

  return (

    <ApiContext.Provider
      value={{
        items,
        name,
        setName,
        item,
        setItem,
        thum,
        setThum,
        selectedItem,
        setSelectedItem,
        timeline,
        setTimeline,
        timeLineList,
        setTimeLineList,
        upDateTimeLine,
        changeTimeLine,
        selectedTimeline,
        setSelectedTimeline,
        sortTimeline,
        modalIsOpen,
        setModalIsOpen,
        detailIsOpen,
        setDetailIsOpen,
        calIsOpen,
        setCalIsOpen,
        preIsOpen,
        setPreIsOpen,
        subIsOpen,
        subPreIsOpen,
        fileType,
        setFileType,
        postItem,
        thum_img,
        setThum_img,
        time,
        setTime,
        span_start,
        setSpan_start,
        span_end,
        setSpan_end,
        interval_start,
        setInterval_start,
        interval_end,
        setInterval_end,
        play_type,
        setPlay_type,
        getContents,
        delContents,
        upDateFlg,
        setUpDateFlg,
        upDateItem,

        groupFlg,
        setGroupFlg,
        gropuTimeLine,
        sortTimeline_group,
        getTimeLineList_all,
        allTimelineList,
        targetDay,
        setTargetDay, heatMap_img, setHeatMap_img,
        targetTime, item_temp, serchMode, setSerchMode, userID, AI_watchIsOpen, setAI_watchIsOpen, AI_flowIsOpen, setAI_flowIsOpen, AI_contentsIsOpen, setAI_contentsIsOpen,
        setTargetTime, getTimeLine, serchItems, getItems, serchKey, timeLineName, setTimeLineName, watchIsOpen, setWatchIsOpen, statusChange,
        getApp, app_admin, setApp_admin, appMode, setAppMode, appContents, setAppContents, loading, setLoading, type_option, setType_option,
        getParam, paramList, setParamList, app, setApp, project, errFlg, setErrFlg, delFlg, setDelFlg

      }}

    >

      {props.children}

      {console.log(timeLineList)}
      {console.log("レンダリング!")}

    </ApiContext.Provider>


  )

}

export default withCookies(ApiContextProvider);