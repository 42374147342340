import React, { useState,useEffect,useContext} from 'react'
import { ApiContext } from "../context/ApiContext";
import { IconContext } from 'react-icons' //IconContextをインポート
import { IoClose } from  "react-icons/io5";

const AI_contents = () => {
    const {setAI_contentsIsOpen,timeLineList} = useContext(ApiContext);
    const ecs = () =>{
        setAI_contentsIsOpen(false);
    }


  return (
    <div>
    <div className='black_bg'>
    <div className='cal_box'>
    <div className='Calendar_block'>
    </div>
    <div className='btn_blue' id='maru_btn' onClick={ecs}>
          <IconContext.Provider value={{ color: '#FFF', size: '50px',className:'shdow' }}>
              <IoClose />
            </IconContext.Provider>
          </div>

    </div>
    
    </div>
    </div>
  )
}

export default AI_contents