import React, { useState, useEffect, useReducer, useContext } from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";


import group from '../img/icon_group.png';
import { ApiContext } from "../context/ApiContext";
import { v4 as uuid } from "uuid";
import { IconContext } from 'react-icons' //IconContextをインポート
import { BsImageFill } from "react-icons/bs";
import { BiMoviePlay } from "react-icons/bi";
import { MdWeb } from "react-icons/md";
import { TbApps } from "react-icons/tb";
import { AiFillFolderOpen } from "react-icons/ai";
import { AiTwotoneCalendar } from "react-icons/ai";
import { FiClock } from "react-icons/fi";
import { AiFillPushpin } from "react-icons/ai";
import { MdMusicNote } from "react-icons/md";


const TimelineItem = ({
  item_obj,
  index,
}) => {
  const { items, timeline, delFlg, setDelFlg } = useContext(ApiContext);
  const [timelineID] = useState(`timeline-${uuid()}`)
  const [thum_cover] = useState(item_obj.thum)
  const [uID] = useState(uuid())


  let type = "";

  let img_urlList = [];








  let imgW_total = 0;
  let imgH_total = 0;


  const wList = []
  const hList = []
  let count = 0;


  let cal_flg = false;
  let clock_flg = false;
  let c_type_flg = false;
  if (item_obj.span_start != "" || item_obj.span_end != "") {
    cal_flg = true;
  }


  if (item_obj.interval_start != "" || item_obj.interval_end != "") {

    clock_flg = true;
  }


  if (item_obj.play_type != "nomal") {
    c_type_flg = true;
  }



  const setup = () => {


  }

  if (item_obj.type == 'group') {
    type = group;
    let imgList = item_obj.type_option.split('-');
    let max = imgList.length;
    if (max > 3) {
      max = 3;
    }
    for (let i = 0; i < max; i++) {
      for (let j = 0; j < items.length; j++) {
        if (items[j].id == imgList[i]) {
          img_urlList.push(items[j].thum)
        }
      }
    }
    //console.log(img_urlList[0]);
  }
  //タイムラインアイテムをプレス
  //ゴミ箱を表示する
  const shoWDelBox_on = () => {
    setDelFlg(true);
  }

  const shoWDelBox_off = () => {
    setDelFlg(false);
  }



  useEffect(() => {
    setup()
  }, []);

  return (

    <Draggable key={timelineID} draggableId={timelineID} index={index}>
      {(provided, snapshot) => (

        <div className="item_box" onMouseUp={shoWDelBox_off} onMouseDown={shoWDelBox_on}
          id={'timeLineObj_' + timelineID}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          {<IconContext.Provider value={{ color: '#1384bf', size: '18px', className: 'item_img' }}><MdMusicNote /></IconContext.Provider>}
          <div className="icon_bg">
            <div className="icon_set">
              {cal_flg && <IconContext.Provider value={{ color: '#1384bf', size: '16px', className: 'item_icon' }}><AiTwotoneCalendar /></IconContext.Provider>}
              {clock_flg && <IconContext.Provider value={{ color: '#1384bf', size: '16px', className: 'item_icon' }}><FiClock /></IconContext.Provider>}
              {c_type_flg && <IconContext.Provider value={{ color: '#1384bf', size: '16px', className: 'item_icon' }}><AiFillPushpin /></IconContext.Provider>}
            </div>

          </div>
          <div className="thum_box">
            {(() => {
              if (item_obj.type == 'group') {


                const str = [];
                {
                  img_urlList.map((item_url, index) => (
                    str.push(<img className={"thum" + index} src={item_url} key={index} alt="" />)

                  ))
                }
                return <div className={uID}>{str}</div>;

              } else {
                return <img id="thum" src={item_obj.thum} alt="" />
              }
            })()}

            <p>{item_obj.name}</p>
          </div>


        </div>
      )}
    </Draggable>
  )
}

export default TimelineItem