import React, { useState,useEffect,useContext} from 'react'
import { ApiContext } from "../context/ApiContext";
import axios from "axios";

import { IconContext } from 'react-icons' //IconContextをインポート
import { BsPlusLg } from "react-icons/bs";
import { IoClose } from  "react-icons/io5";
import { MdPreview } from "react-icons/md";
import { TbCloudUpload } from "react-icons/tb";







const App = ({app_obj}) => {
    const {upDateFlg, setUpDateFlg,appMode,setAppMode,postItem,upDateItem,setFileType,loading, setLoading,getParam} = useContext(ApiContext);
    const {thum, setThum} = useContext(ApiContext);
    const {name, setName} = useContext(ApiContext);
    const {time, setTime} = useContext(ApiContext);
    const {type_option, setType_option} = useContext(ApiContext);
    const {paramList, setParamList} = useContext(ApiContext);
    const obj =[];
    const [url,setUrl] = useState('');
    const detailOff = () =>{
        // setModalIsOpen(false);
        setAppMode(!appMode);
     
    }
    const changeMonitor = (event) => {
      const previewURL = getURL()
      //setSelectedTimeline(event.target.value);
      //changeTimeLine(event.target.value);
      //https://ha-app.com/area_weather_
      //alert(event.target.value)
      //setType_option('https://ha-app.com/area_weather_'+event.target.value+'/')
      //setUrl('https://ha-app.com/area_weather_'+event.target.value+'/')
    }


    const getURL = () =>{

      let previewURL = app_obj.main_url;
      for (let l = 0; l < paramList.length; l++){
      
        const paramName = paramList[l].param_name;
        

        const str = document.getElementById(paramName).value;

        if(paramList[l].param_type == 'folder'){
          previewURL   += str + "/"
        }else{
          previewURL+='?'+paramName+'='+ str;
        }
       
      }
      setType_option(previewURL)
      return previewURL;

    }

    const preview = () =>{

      let previewURL = getURL()
      if(app_obj.tag=="web"){
        previewURL = type_option;
      }
      window.open(previewURL, '_blank');

    }

   



      /*
      if(upDateFlg){
        upDateItem();
      }else{
        postItem();
      }
      */
      
 
    const saveItem = async () => {
      
      
      setLoading(true);
      //console.log("http://127.0.0.1:8000/api/web2Img/?url=" + url)
      //
      try {
        const res = await axios.get("http://127.0.0.1:8000/api/web2Img/?url=" + type_option, {
          
        });
        let thumTmp = "data:image/png;base64," + res.data;
        console.log(res.data)
        thumTmp = createJpegFile4Base64(thumTmp, "mov.png");
 
        setThum(thumTmp);
        

       
      } catch {
        console.log("error");
      }

    }

  useEffect(() => {
    
    if(app_obj.param !=undefined){
      getParam(app_obj.param)
     
    }
   
    


    setThum(null)
    setName('');
    setTime(15);
    setFileType('App');
    if(app_obj.tag=="web"){
      setType_option('')
    }

    


  }, []);

 

  useEffect(() => {
    if(thum != null){
      postItem();
    }
  }, [thum]);

  /////////////////////////////////////////////
//引数はbase64形式の文字列と作成するファイルオブジェクトのファイル名
const createJpegFile4Base64 = function (base64, name) {
  // base64のデコード
  var bin = atob(base64.replace(/^.*,/, ''));
  // バイナリデータ化
  var buffer = new Uint8Array(bin.length);
  for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
  }
  // ファイルオブジェクト生成(この例ではjpegファイル)
  return new File([buffer.buffer], name, {
      type: "image/png"
  });
};


  return (
    <div>
        <div className='black_bg'>
                <div className="app_bg">
                  <div className='app_title'>{app_obj.appName}</div>
                  <div className='btn_nomal' id='maru_btn' onClick={detailOff}>
                  <IconContext.Provider value={{ color: '#FFF', size: '40px',className:'shdow' }}>
                    <IoClose />
                  </IconContext.Provider>
                  </div>
                  <input id="input" className="fileName" name="" type="text" value={name} placeholder={"名称未定"} onChange={(event) => setName(event.target.value)}/>
                  {
                  (() => {
                    if(app_obj.tag=="web"){
                    return(
                      <input id="input" className="urlStr" name="" type="text" value={type_option} placeholder="URLを入力" onChange={(event) => setType_option(event.target.value)}/>

                    )
                    }

                  })()
                  }
                  
                  <div className='app_select'>
                  
               
                  {paramList.map((param, index) => (
                  
                 
                  <select key ={index} defaultValue="" id={param.param_name} className ='app_select_size'onChange={(e) => changeMonitor(e) }>
                  <option key ='0'  value="" >{param.param_title}</option>
                   {
                    
                    param.param_value.split("|").map((param_values, index2) => (
                    <option key ={index2} value={param_values.split(",")[1]}>{param_values.split(",")[0]}</option>
                    ))}
                  </select>
                  ))}
                  
                  </div>
                  <div className='timeBox'>
                <input id="input" className="fileTime" name="" type="text" value={time} onChange={(event) => setTime(event.target.value)}/>
                <p>秒</p>
            </div>
                  <div className='btn_nomal' id='mini_btn' onClick={preview}>
                    <IconContext.Provider value={{ color: '#FFF', size: '40px',className:'shdow' }}>
                      <MdPreview />
                    </IconContext.Provider>
                    プレビュー

                  </div>
                  <div className='btn_nomal' id='send_btn' onClick={saveItem}>
                  <IconContext.Provider value={{ color: '#FFF', size: '60px',className:'shdow_upload' }}>
                      <TbCloudUpload />
                  </IconContext.Provider>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default App