import React, { useState, useEffect, useContext } from 'react'
import add from '../../img/add.png';
import { ApiContext } from "../../context/ApiContext";

import { IconContext } from 'react-icons' //IconContextをインポート
import { BsPlusLg } from "react-icons/bs";


const AddBtn = () => {
  const { modalIsOpen, setModalIsOpen } = useContext(ApiContext);
  const addItem = () => {
    setModalIsOpen(!modalIsOpen);
  }
  return (

    <div className="main_btn">
      <div className="main_btn_box" >
        <div className="button_bg"></div>
        <div className="add_btn" onClick={addItem}>
          <IconContext.Provider value={{ color: '#FFF', size: '50px', className: 'shdow_add' }}>
            <BsPlusLg />
          </IconContext.Provider>
        </div>
      </div>
    </div>





  )
}

export default AddBtn