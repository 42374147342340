import React, { useState,useEffect,useReducer,useContext } from 'react'
import faker from 'faker';
import { ApiContext } from "../context/ApiContext";

const Heatmap = (props) => {

  
  const {heatMap_img} = useContext(ApiContext);
  useEffect(() => {

    setGrid();
  }, [[],heatMap_img]);

  const hittest = (self, target) =>{
    //console.log(self.y)
   
    const myTop    = self.y;
    const myBottom = self.y + self.h;
    const myLeft   = self.x;
    const myRight  = self.x + self.w;
  
    const targetTop    = target.y;
    const targetBottom = target.y + target.h;
    const targetLeft   = target.x;
    const targetRight  = target.x + target.w;
  
    return (myTop < targetBottom && targetTop < myBottom) && (myLeft < targetRight && targetLeft < myRight);
 

  }

  const setGrid = () =>{

      const ctx = document.getElementById('grid').getContext('2d');
      ctx.clearRect(0, 0, props.width, props.height);
 
      const targetW = props.width;
      const targetH = props.height;
      const block = 10;
      const blockScale = 3;
      const wCount = targetW/block;
      const hCount = targetH/block;

      const dumyX = [];
      const dumyY = [];

      for (let i = 0; i < 500; i++) {
        //let val = faker.seed(i)
        let val = faker.random.number({ min: 0, max: props.width });
        dumyX.push(val)
      }

      for (let i = 0; i < 500; i++) {
        //let val = faker.seed(i+1000000)
        let val = faker.random.number({ min: 0, max: props.height });
        dumyY.push(val)
      }

      

      ctx.fillStyle = "rgb(110, 255, 0)";
      let hitList = [];
      let gridList = [];
      let bestScore = 0;
      for (let i = 0; i < dumyX.length; i++) {
        //ctx.fillRect(dumyX[i], dumyY[i], block*blockScale, block*blockScale)
        let obj = new Object;
        obj.w = block*blockScale;
        obj.h = block*blockScale;
        obj.x = dumyX[i];
        obj.y = dumyY[i];
        hitList.push(obj);
      }
     
      //ctx.fillRect(dumyX[0], dumyY[0], block*3, block*3)


      
      // for文を利用
      let count = 0
      for (let i = 0; i < hCount; i++) {
        for (let j = 0; j < wCount; j++) {

          // fillRect(開始x座標, 開始y座標, 描画幅, 描画高さ)
          //ctx.fillStyle = "rgb(220, 220, 220)";
          
          
          

          /*
          var text = count;
          ctx.font = '8px serif';
          ctx.fillStyle = '#000';
          ctx.textBaseline = 'center';
          ctx.textAlign = 'center';
          ctx.fillText(text,j * 50+25, i * 50+25+10, 49, 49);
          */
          let score = 0
          for (let k = 0; k < hitList.length; k++) {

            let target = new Object;
            target.w = block*blockScale;
            target.h = block*blockScale;
            target.x = j * block - block/2;
            target.y = i * block - block/2;
            const hitFlg= hittest(hitList[k],target)
            if(hitFlg){
              score ++;
              //ctx.fillStyle = "rgb(0, 0, 255)";
            }

          }
          //console.log(score);
          //ベストスコア割り出し
          if(bestScore<score){
            bestScore = score
          }

          //ctx.fillRect(j * block, i * block, block, block);
          
          count +=1 ;
          
        }
      } 
      console.log('BSET_SCORE:'+ bestScore);
      const ratio = 100/bestScore;
      for (let i = 0; i < hCount; i++) {
        for (let j = 0; j < wCount; j++) {
          let score = 0
          for (let k = 0; k < hitList.length; k++) {
            let target = new Object;
            target.w = block*blockScale;
            target.h = block*blockScale;
            target.x = j * block - block/2;
            target.y = i * block - block/2;
            const hitFlg= hittest(hitList[k],target)
            if(hitFlg){
              score ++;
              //ctx.fillStyle = "rgb(0, 0, 255)";
            }
          }
          ctx.fillStyle = "rgb(220, 220, 220)";
          if(score>10){
            //ctx.fillStyle = "rgb(220, 0, 0)";
            
          }
          const color = thermo(score/bestScore)
          ctx.fillStyle = color;
          ctx.fillRect(j * block, i * block, block, block);
        }
      }









    }

    // サーモグラフィの作成
  const thermo = function(val){
    const sigmoid = function(x, offset, gain) {
      return ((Math.tanh(((x + offset ) * gain )/2)+1)/2);
    }

    const toHex = function(num) {
      let hex = Math.floor(num).toString(16);

      if (hex.length == 1) {
          hex = '0' + hex;
      }
      return hex;
    }

    val = (val*2) - 1;
    let red = sigmoid(val, -0.2, 10);
    let blue = 1 - sigmoid(val, 0.2, 10);
    let green = sigmoid(val, 0.6, 10) + (1 - sigmoid(val, -0.6, 10))
    green = green - 1.0;

    return '#' + toHex(red*255) + toHex(green*255*0.9) + toHex(blue*255);
}


    return (
      <div>
        <canvas id="grid"width = '1080'  height="1920"></canvas>
         
      </div>
  
    )
  }

export default Heatmap