import React from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import faker from 'faker';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  
  




  export const Line_graph = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true,
        text: "視聴距離"
      }
    }
  };
  
  const labels = ['0m', '0.5m', '1m', '1.5m', '2m', '2.5m', '3m','3.5m','4m','4.5m','5m'];
  
  const data = {
    labels,
    datasets: [
      {
        label: '男性',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),

        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: '女性',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        
      },
    ],
  };
  return (
    <div>
        <Line options={options} data={data} />
        
    </div>
  )
}

export default Line_graph