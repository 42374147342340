import React, { useState, useEffect, useReducer, useContext } from 'react'
import Items from './Items'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ApiContext } from "../context/ApiContext";
import { HeaderContext } from "../context/HeaderContext";

export const ItemList = () => {
  const { items, item_temp, serchMode } = useContext(ApiContext);
  const { setScrollFlg } = useContext(HeaderContext);

  const scroll = (e) => {
    if (e.target.scrollTop > 10) {
      setScrollFlg(true)
    } else {
      setScrollFlg(false)
    }
  }

  return (
    //isDropDisabled={true}
    //<DragDropContext onDragEnd={onDragEnd}>

    <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={true}>
      {(provided) => (
        <div className='item'
          ref={provided.innerRef}
          {...provided.droppableProps}
          onScroll={scroll}
        >
          <div className='item_scroll' >




            {serchMode
              ? item_temp.map((item_obj, index) => (<Items key={item_obj.id} index={index} item_obj={item_obj} />))
              : items.map((item_obj, index) => (<Items key={item_obj.id} index={index} item_obj={item_obj} />))
            }



            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>

  )
}

export default ItemList