import React, { useState, useEffect, useReducer, useId, useInsertionEffect, useContext } from 'react'

import { withCookies } from "react-cookie";
import axios from "axios";

import Navi from './header/Navi';

import MainContents from './MainContents';

import logo from '../img/logo.png';
import { ApiContext } from "../context/ApiContext";

import Popup from './Popup';
import Loading from './Loading';
import { IconContext } from 'react-icons' //IconContextをインポート
import { AiFillLock } from "react-icons/ai";

import { useAnimation, motion } from "framer-motion";
import Toast from './Toast';

import {
  START_FETCH,
  FETCH_SUCCESS,
  ERROR_CATCHED,
  INPUT_EDIT,
  LOGIN_MODE,
} from "./actionTypes";

const initialState = {
  isLoading: false,
  isLoginView: true,
  isVisible: true,
  error: "",
  credentialsLog: {
    username: "",
    password: "",
  },
};


const loginReducer = (state, action) => {
  switch (action.type) {
    case START_FETCH: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ERROR_CATCHED: {
      return {
        ...state,
        error: "Email or password is not correct !",
        isLoading: false,
      };
    }
    case INPUT_EDIT: {
      return {
        ...state,

        credentialsLog: {
          ...state.credentialsLog,
          [action.inputName]: action.payload,
        },
        error: "",
      };
    }
    case LOGIN_MODE: {
      return {
        ...state,
        isVisible: false,
      };
    }
    default:
      return state;
  }
};




export const Login = (props) => {



  const { heatMap_img } = useContext(ApiContext);
  const { errFlg, setErrFlg } = useContext(ApiContext);
  const [loginFlg, setLoginFlg] = useState(false);
  const [state, dispatch] = useReducer(loginReducer, initialState);
  const [loginName, setLoginName,] = useState('');
  const controls = useAnimation()
  const inputChangedLog = () => (event) => {
    dispatch({
      type: INPUT_EDIT,
      inputName: event.target.name,
      payload: event.target.value,
    });
  };

  const login = async (event) => {
    event.preventDefault();
    try {
      dispatch({ type: START_FETCH });
      setLoginName(state.credentialsLog.username);
      const res = await axios.post(
        `./ds_project/api/v1/rest-auth/login/`,
        state.credentialsLog,
        {
          headers: { "Content-Type": "application/json" },
        }
      );


      props.cookies.set("jwt-token", res.data.key);
      res.data.key
        ? (mainView())
        : (console.log('err'));
      dispatch({ type: FETCH_SUCCESS });

    } catch {
      setErrFlg(true);
      controls.start({
        x: [100, -75, 50, 0], transition: { duration: 0.4, type: "spring" },

      })

      dispatch({ type: ERROR_CATCHED });

    }

  }

  function toast() {


    return (<Toast icon="warning" massage="ユーザーIDまたはパスワードが違います" />);



  }
  const mainView = () => {
    //alert("ON")
    setLoginFlg(true);
    controls.start({
      opacity: [1, 0], scale: [1, 1.2], transition: { duration: 0.4, type: "easeOut" },

    })
    //dispatch({ type: LOGIN_MODE });

  };

  const end = () => {

    console.log('OK')
    if (loginFlg) {
      dispatch({ type: LOGIN_MODE });
    }

  };

  return (
    <>

      <Popup />
      <Loading />
      {!state.isVisible && <Navi user={loginName} />}
      {!state.isVisible && <MainContents />}

      <div className={state.isVisible ? 'login_container' : 'invisible'}>

        <motion.div className='login_box' animate={controls} onAnimationComplete={end}>
          <img src={logo} alt="" />

          <p>ver.2.0.1</p>

          <form onSubmit={login}>

            <input id="input" name="username" type="text" placeholder="ユーザーID" value={state.credentialsLog.username} onChange={inputChangedLog()} />

            <input id="input" name="password" type="password" placeholder="パスワード" value={state.credentialsLog.password} onChange={inputChangedLog()} />

            <button className="red" type="submit">
              <div className='login_set'>
                <IconContext.Provider value={{ color: '#1384bf', size: '25px' }}>
                  <AiFillLock />
                </IconContext.Provider>
                <p id='login'>Login</p>
              </div>
            </button>

          </form>
        </motion.div>
        {errFlg && toast()}
      </div>
    </ >


  )
}



export default withCookies(Login);






