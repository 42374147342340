import React, { useState,useEffect,useContext} from 'react'
import sample from '../img/sample.png';
import { ApiContext } from "../context/ApiContext";

//setAllTimelineList

const Calendar_block = () => {
const {allTimelineList} = useContext(ApiContext);
  return (
    <div className='Calendar_block'>
       
       {allTimelineList.map((timelineObj, index) => (
         
         <div className='day_block' key={'block_id_'+index}>

         <p>{timelineObj.day}</p>
         <div className='day_main'>
             <div className='day_scroll'>
             {timelineObj.map((itemObj, index) => (
                 <div className='day_item' key={'item_id_'+index}>
                    
                     <img src={itemObj[0].thum} alt="" />
                     <p>{itemObj[0].name}</p>
                 </div>
                  ))} 
             </div>
         </div>
     </div>
     
       ))}
        
        

    </div>
  )
}

export default Calendar_block