import React from 'react';
import ReactDOM from 'react-dom';

import { CookiesProvider } from 'react-cookie';
import './scss/parts.scss'
import './scss/common.scss'
import Login from './components/Login';

import ItemPage from './components/ItemPage';
import AppCmponets from './components/AppCmponets';
import reportWebVitals from './reportWebVitals';
import { ApiContext } from "./context/ApiContext";
import ApiContextProvider from "./context/ApiContext";
import HeaderProvider from "./context/HeaderContext";

ReactDOM.render(

  <React.StrictMode>
    <CookiesProvider>
      <ApiContextProvider>
      <HeaderProvider>
        <Login />
      </HeaderProvider>
      </ApiContextProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals




