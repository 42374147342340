import React, { useState, useEffect, useContext } from 'react'
import { HeaderContext } from "../../context/HeaderContext";
import NaviHader from './NaviHeader';
import AddBtn from './AddBtn';
import Search from './Search';

const Navi = (props) => {
  const { scrollFlg } = useContext(HeaderContext);
  return (
    <div className='navi'>

      <NaviHader />

      <AddBtn />

      {!scrollFlg && <Search />}


    </div>
  )
}

export default Navi