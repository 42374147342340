import React, { useState, useEffect, useReducer, useContext } from 'react'
import parse from 'html-react-parser';


import group from '../img/icon_group.png';

import setting from '../img/setting.png';

import { Draggable } from "react-beautiful-dnd";
import styled from 'styled-components';
import { ApiContext } from "../context/ApiContext";
import { v4 as uuid } from "uuid";

import { IconContext } from 'react-icons' //IconContextをインポート
import { BsImageFill } from "react-icons/bs";
import { BiMoviePlay } from "react-icons/bi";
import { MdWeb } from "react-icons/md";
import { TbApps } from "react-icons/tb";
import { AiFillFolderOpen } from "react-icons/ai";
import { AiTwotoneCalendar } from "react-icons/ai";
import { FiClock } from "react-icons/fi";
import { AiFillPushpin } from "react-icons/ai";
import { MdMusicNote } from "react-icons/md";

const Content = styled.div`
    margin-right: 200px;
`;

const Item = styled.div`
    display: flex;
    user-select: none;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
    align-items: flex-start;
    align-content: flex-start;
    line-height: 1.5;
    border-radius: 3px;
    background: #fff;
    border: 1px ${props => (props.isDragging ? 'dashed #FF0000' : 'solid #ddd')};
`;

const Clone = styled.div`
    ~div {
      transform: none !important;
    }
`;

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? "#757ce8" : "white",
  ...draggableStyle
});

export const Items = ({
  itemsList,
  item_obj,
  index,
}) => {
  const { items, item, setItem, detailIsOpen, setDetailIsOpen, getContents, delContents, gropuTimeLine, setUpDateFlg, setGroupFlg, setSelectedItem, sortTimeline_group, setThum, setFileType, type_option, setType_option } = useContext(ApiContext);
  const [img_urlList, setImg_urlList] = useState([]);
  let type = "";





  let cal_flg = false;
  if (item_obj.span_start != "" || item_obj.span_end != "") {
    cal_flg = true;
  }

  let clock_flg = false;
  if (item_obj.interval_start != "" || item_obj.interval_end != "") {
    clock_flg = true;
  }

  let c_type_flg = false;
  if (item_obj.play_type != "nomal" && item_obj.play_type != '') {
    c_type_flg = true;
  }

  //console.log(item_obj);
  const handleOnDoubleClick = (id) => {
    setSelectedItem(id)
    setUpDateFlg(true);
    getContents(id);
    setThum(item_obj.thum)
    setFileType(item_obj.type)
    console.log(item_obj)
    setItem(item_obj.file)
    setType_option(item_obj.type_option)
    if (item_obj.type == 'group') {

      gropuTimeLine(item_obj.type_option);

      setGroupFlg(true);

    } else {
      setDetailIsOpen(!detailIsOpen);

    }


  }
  const delItem = (id) => {
    delContents(id);
  }

  const onClick = (e, isRight, id) => {
    e.preventDefault();
    if (isRight) {
      // 右クリック
      console.log(id)
    }
  };


  //const [count,setCount] = useState(0)

  let imgW_total = 0;
  let imgH_total = 0;

  const [uID] = useState(uuid())

  const setup = () => {
    if (item_obj.type == 'group') {
      type = group;
      let imgList = item_obj.type_option.split('-');
      let img_urlList_temp = [];

      let max = imgList.length;
      if (max > 3) {
        max = 3;
      }
      for (let i = 0; i < max; i++) {
        for (let j = 0; j < items.length; j++) {
          if (items[j].id == imgList[i]) {
            img_urlList_temp.push(items[j].thum)
          }
        }
      }
      setImg_urlList(img_urlList_temp)
    }
  }



  useEffect(() => {
    setup()
  }, []);


  const html = "<IconContext.Provider value={{ color: '#1384bf', size: '18px' ,className:'item_img'}}><BsImageFill /></IconContext.Provider>"

  return (


    <Draggable id={String(item_obj.id)} draggableId={String(item_obj.id)} index={index}>

      {(provided, snapshot) => (
        <React.Fragment>

          <div className="item_box"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}>

            {<IconContext.Provider value={{ color: '#1384bf', size: '18px', className: 'item_img' }}><MdMusicNote /></IconContext.Provider>}






            <div className="icon_bg" >
              <div className="icon_set">
                {cal_flg && <IconContext.Provider value={{ color: '#1384bf', size: '16px', className: 'item_icon' }}><AiTwotoneCalendar /></IconContext.Provider>}
                {clock_flg && <IconContext.Provider value={{ color: '#1384bf', size: '16px', className: 'item_icon' }}><FiClock /></IconContext.Provider>}
                {c_type_flg && <IconContext.Provider value={{ color: '#1384bf', size: '16px', className: 'item_icon' }}><AiFillPushpin /></IconContext.Provider>}
              </div>
              <img className="setting_btn" src={setting} alt="" onClick={(e) => delItem(item_obj.id)} />
            </div>
            <div className="thum_box"
              onClick={(e) => handleOnDoubleClick(item_obj.id)}

            >
              {(() => {
                if (item_obj.type == 'group') {

                  const str = [];
                  {
                    img_urlList.map((item_url, index) => (
                      str.push(<img className={"thum" + index} src={item_url} key={index} alt="" />)

                    ))
                  }
                  return <div className={uID}>{str}</div>;

                } else {
                  return <img id="thum" src={item_obj.thum} alt="" />
                }
              })()}
              <p>{item_obj.name}</p>
            </div>
          </div>
          {snapshot.isDragging && (
            <div className="item_box2">

            </div>
          )}

        </React.Fragment>
      )}


    </Draggable>







  );
}

export default Items