import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  } from "chart.js";
  import { Bar } from "react-chartjs-2";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  
 
  

  
  
  


export const Bar_graph = (props) => {
  const labels = ["0時", "1時", "2時", "3時", "4時", "5時", "6時","7時", "8時", "9時", "10時", "11時", "12時", "13時","14時", "15時", "16時", "17時", "18時", "19時", "20時","21時", "22時", "23時"];
  const data1 = [0,0,0,0,0,0,0,0,12, 11, 14, 52, 19, 72, 56,12, 11, 14, 52, 14, 32, 36];
  const data2 = [0,0,0,0,0,0,0,0,22, 31, 17, 32, 24, 62, 66,22, 31, 17, 32, 24, 62, 66];
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true,
        text: props.title
      }
    }
  };

  const data = {
    labels, // x軸のラベルの配列
    datasets: [
      {
        label: "男性", // 凡例
        data: data1,        // データの配列(labelsと要素数同じ)
        backgroundColor: "rgba(53, 162, 235, 0.5)" // グラフの棒の色
      },
      {
        label: "女性",
        data: data2,
        backgroundColor: "rgba(255, 99, 132, 0.5)"
      }
    ]
  };
 
  return (
    <div>
        <Bar options={options} data={data} />;
    </div>
  )
}

export default Bar_graph