import React, { useState, useEffect, useReducer, useContext } from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ItemList from './ItemList';
import TimeLine from './TimeLine';
import DelBox from './DelBox';
import { ApiContext } from "../context/ApiContext";


const Main = () => {
  const { items, timeline, delFlg, setDelFlg, setTimeline, upDateTimeLine, setTimeLineStr, groupFlg, gropuTimeLine, statusChange } = useContext(ApiContext);

  const [itemDrag, setItemDrag] = React.useState(false);
  const sendTimeLine = (timeline_items) => {
    var timeLineTemp = "";
    for (let i = 0; i < timeline_items.length; i++) {
      timeLineTemp += (timeline_items[i].id);
      timeLineTemp += "-"
    }
    timeLineTemp = timeLineTemp.slice(0, -1);

    //console.log("groupFlg = " + groupFlg);
    if (groupFlg) {
      gropuTimeLine(timeLineTemp)
    } else {
      upDateTimeLine(timeLineTemp)
    }

  }
  const onDragStart = (result) => {
    const { source } = result;
    if (source.droppableId == 'timeline') {
      timeline[source.index].dndFlg = true;

      setItemDrag(true);

    }

  };


  const onDragEnd = (result) => {
    //const item = document.getElementById('delBox');
    //item.style.visibility = "hidden";
    setDelFlg(false);
    const { source, destination } = result;




    if (source.droppableId == 'timeline' && destination.droppableId == "delArea" && setItemDrag) {

      const item = document.getElementById('timeLineObj_' + result.draggableId);
      item.style.opacity = 0;

      const timeline_items = Array.from(timeline);
      const remove = timeline_items.splice(result.source.index, 1); //[2,3]
      setTimeline(timeline_items);
      sendTimeLine(timeline_items);
      return;

    } else if (destination == null) {
      return;
    }
    if (source.droppableId == destination.droppableId) {


      const timeline_items = Array.from(timeline);
      const remove = timeline_items.splice(result.source.index, 1); //[2,3]
      timeline_items.splice(result.destination.index, 0, remove[0]); //[2,1,3]
      setTimeline(timeline_items);
      sendTimeLine(timeline_items);

      //statusChange('未配信');


    } else if (destination.droppableId == 'timeline') {

      const targetID = result.draggableId;
      let targetNum = 0;
      for (let i = 0; i < items.length; i++) {
        if (items[i].id == targetID) {
          targetNum = i;
          break;
        }
      }



      const obj = new Object();
      obj.id = items[targetNum].id;
      obj.thum = items[targetNum].thum;
      obj.file = items[targetNum].file;
      obj.name = items[targetNum].name;
      obj.type = items[targetNum].type;
      obj.time = items[targetNum].time;
      obj.type_option = items[targetNum].type_option;
      obj.span_start = items[targetNum].span_start;
      obj.span_end = items[targetNum].span_end;
      obj.interval_start = items[targetNum].interval_start;
      obj.interval_end = items[targetNum].interval_end;
      obj.play_type = items[targetNum].play_type;
      const timeline_items = Array.from(timeline);
      timeline_items.splice(result.destination.index, 0, obj);

      setTimeline(timeline_items);
      sendTimeLine(timeline_items);
      setItemDrag(false);

      //statusChange('未配信');


    }

  };

  return (


    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <ItemList />
      {delFlg && <DelBox />}

      <TimeLine />
    </DragDropContext>

  )
}

export default Main