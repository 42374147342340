import React, { useState,useEffect,useReducer,useContext } from 'react'
import { ApiContext } from "../context/ApiContext";
import useTimeout from "./Timeout"
import sample from '../img/file.jpg';

import { IconContext } from 'react-icons' //IconContextをインポート
import { MdPreview } from "react-icons/md";
import { IoClose } from  "react-icons/io5";
import { IoWarning } from  "react-icons/io5";

//MdPreview

const Preview = () => {
  const {getTimeLineList_all,preIsOpen,setPreIsOpen,allTimelineList,targetDay,setTargetDay,targetTime,setTargetTime} = useContext(ApiContext);

  const [contents, setContents] = useState('');
  const [hasTimeElapsed, setHasTimeElapsed] = React.useState(false);
  const [abortTimeout, setAbortTimeout] = React.useState(true);
  const [contentsCount, setContentsCount] = useState(0);
  const [previewFlg, setPreviewFlg] = useState(true);
  //
  
  const [timeOUT, setTimeOUT] = useState(0);
  useEffect(() => {
    
    setContentsCount(0);
  }, []);

  useEffect(() => {
    
      if(contentsCount != 0){
        
        setContentsCount(0);
      }else{
        contentsSetup();
      }
     
  }, [allTimelineList]);


  useEffect(() => {
   

    const v = document.getElementById("preview_video");
    
    if(v != null){
     
    
      v.muted = true;
      v.onloadeddata = function () {
        v.play();
      }
      v.onended = function (e) {
        next();
      }

    }
    
  }, [contents]);
  
  useEffect(() => {


  }, [contentsCount]);
  useEffect(() => {
    contentsSetup()
  }, [contentsCount]);

  const contentsSetup = () =>{
    
    if(allTimelineList[0][contentsCount][0].type == "img"){
      setContents(<img className ="preview_img" src={allTimelineList[0][contentsCount][0].file} alt="" />);
      setTimeOUT((allTimelineList[0][contentsCount][0].time)*1000);
      setAbortTimeout(false);
      setPreviewFlg(true)
    }else if(allTimelineList[0][contentsCount][0].type == "mov"){
      setContents(<video id="preview_video" src={allTimelineList[0][contentsCount][0].file}></video>);
      const v = document.getElementById("preview_video");
      setPreviewFlg(true)
      
    }else{
      setPreviewFlg(false)
    }


  }

  const next = () =>{
      if(contentsCount >= allTimelineList[0].length-1){
        setAbortTimeout(true)
        setContentsCount(0);
        
      }else{
        setAbortTimeout(true)
        setContentsCount(contentsCount +1);
      }
    
  }
  
  const timeoutRef = useTimeout(() => {
    
    //console.log(contentsCount)
    next();
    setAbortTimeout(true)

  }, abortTimeout ? null : (allTimelineList[0][contentsCount][0].time)*1000);
  
 



  



  const esc = () =>{
    //setup();
    //setAbortTimeout(false)
    setPreIsOpen(false);
  }
  const timeChange = () =>{
    setAbortTimeout(true)
    //window.clearTimeout(timeoutRef.current);
    
    const day = document.getElementById("cal_day").value;

    const time = document.getElementById("cal_time").value;
    const targetDay = new Date(day.split('-')[0], day.split('-')[1], day.split('-')[2], time.split(':')[0], time.split(':')[1]);
    getTimeLineList_all(targetDay);
 

  }
  return (
    <div className = 'preview_bg' >
      <div className="cal_form">
      <input className ="cal_day" id="cal_day" type="date" name="example1" defaultValue={targetDay}></input>
      
      <input className ="cal_time" id="cal_time" type="time" name="example1" defaultValue={targetTime}></input>
      <button className='btn_blue' id='btn' onClick={timeChange}>
      <IconContext.Provider value={{ color: '#FFF', size: '30px',className:'shdow' }}>
        <MdPreview />
      </IconContext.Provider>
        <p>プレビュー</p>
        </button>

        <div className='btn_blue' id='maru_btn_mini' onClick={esc}>
          <IconContext.Provider value={{ color: '#FFF', size: '50px',className:'shdow' }}>
              <IoClose />
          </IconContext.Provider>
        </div>

      </div>
      <div className='preview_box'>
      {previewFlg
        ? <div>{contents}</div>
        : <div className='center_box'>
          <IconContext.Provider value={{ color: '#FFF', size: '80px',className:'shdow' }}>
              <IoWarning />
          </IconContext.Provider>

          {'「'+ allTimelineList[0][contentsCount][0].name + '」'+ 'は'}
          <br/>ブラウザでの自動プレビューに対応していません。

          <button className='btn_blue' id='btn' onClick={next}>
          <p>SKIP</p>
          </button>
          </div>
      }

        
        
      </div>
      
    </div>
  )
}

export default Preview