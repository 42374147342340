import React, { useState, useEffect, useContext } from 'react'
import { ApiContext } from "../../context/ApiContext";

import { IconContext } from 'react-icons' //IconContextをインポート
import { FaSearch } from "react-icons/fa";
import { FaWindowClose } from "react-icons/fa";
import { BsImageFill } from "react-icons/bs";
import { BiMoviePlay } from "react-icons/bi";
import { MdWeb } from "react-icons/md";
import { TbApps } from "react-icons/tb";
import { AiFillFolderOpen } from "react-icons/ai";

//BiMoviePlay

//BsImageFill


//MdWeb




const Search = () => {
  const [serchWord, setSerchWord] = useState('');
  const [serchFlg, setSerchFlg] = useState(false);
  const [serchImg, setSerchImg] = useState(false);
  const [serchMov, setSerchMov] = useState(false);
  const [serchURL, setSerchURL] = useState(false);
  const [serchApp, setSerchApp] = useState(false);
  const [serchFolder, setSerchFolder] = useState(false);

  const { getItems, serchItems, serchKey, serchMode, setSerchMode } = useContext(ApiContext);


  const serchFunc = () => {

    setSerchFlg(!serchFlg)

    if (serchFlg) {
      setSerchWord('');
      getItems();
      setSerchMode(false)

    } else {

      serchKey(serchWord)
      setSerchMode(true)

    }
    setSerchImg(false)
    setSerchMov(false)
    setSerchURL(false)
    setSerchApp(false)
    setSerchFolder(false)


  }
  const serchImg_func = () => {
    setSerchImg(!serchImg)

    if (serchImg) {
      getItems();
      setSerchMode(false)

    } else {
      serchItems('img');
      setSerchMode(true);
    }

    setSerchMov(false)
    setSerchURL(false)
    setSerchApp(false)
    setSerchFolder(false)

  }

  const serchMov_func = () => {

    setSerchMov(!serchMov)
    setSerchMode(!serchMode)
    if (serchMov) {
      getItems();
      setSerchMode(false)

    } else {
      serchItems('mov');
      setSerchMode(true)
    }
    setSerchImg(false)

    setSerchURL(false)
    setSerchApp(false)
    setSerchFolder(false)

  }

  const serchURL_func = () => {
    setSerchURL(!serchURL)

    if (serchURL) {
      getItems();
      setSerchMode(false)

    } else {
      serchItems('url');
      setSerchMode(true)
    }


    setSerchImg(false)
    setSerchMov(false)

    setSerchApp(false)
    setSerchFolder(false)


  }

  const serchApp_func = () => {
    setSerchApp(!serchApp)

    if (serchApp) {
      getItems();
      setSerchMode(false)

    } else {
      serchItems('App');
      setSerchMode(true)
    }

    setSerchImg(false)
    setSerchMov(false)
    setSerchURL(false)

    setSerchFolder(false)

  }

  const serchFolder_func = () => {
    setSerchFolder(!serchFolder)

    if (serchFolder) {
      getItems();
      setSerchMode(false)

    } else {
      serchItems('group');
      setSerchMode(true)
    }
    setSerchImg(false)
    setSerchMov(false)
    setSerchURL(false)
    setSerchApp(false)
  }

  return (

    <div className='search'>
      <div className='search_box'>
        <input id="input" name="username" type="text" placeholder="キーワード" onChange={(e) => setSerchWord(e.target.value)} />
        <div className="search_btn" onClick={serchFunc}>
          {serchFlg
            ? <IconContext.Provider value={{ color: '#1384bf', size: '25px' }}><FaWindowClose /></IconContext.Provider>
            : <IconContext.Provider value={{ color: '#1384bf', size: '25px' }}><FaSearch /></IconContext.Provider>
          }
        </div>
        <div className='search_btn_set'>
          {serchImg
            ? <button className="btn_off" onClick={serchImg_func}><IconContext.Provider value={{ color: '#1384bf', size: '25px' }}><BsImageFill /></IconContext.Provider></button>
            : <button className="btn" onClick={serchImg_func}><IconContext.Provider value={{ color: '#1384bf', size: '25px' }}><BsImageFill /></IconContext.Provider></button>
          }
          {serchMov
            ? <button className="btn_off" onClick={serchMov_func}><IconContext.Provider value={{ color: '#1384bf', size: '28px' }}><BiMoviePlay /></IconContext.Provider></button>
            : <button className="btn" onClick={serchMov_func}><IconContext.Provider value={{ color: '#1384bf', size: '28px' }}><BiMoviePlay /></IconContext.Provider></button>

          }
          {serchURL
            ? <button className="btn_off" onClick={serchURL_func}><IconContext.Provider value={{ color: '#1384bf', size: '28px' }}><MdWeb /></IconContext.Provider></button>
            : <button className="btn" onClick={serchURL_func}><IconContext.Provider value={{ color: '#1384bf', size: '28px' }}><MdWeb /></IconContext.Provider></button>
          }
          {serchApp
            ? <button className="btn_off" onClick={serchApp_func}><IconContext.Provider value={{ color: '#1384bf', size: '28px' }}><TbApps /></IconContext.Provider></button>
            : <button className="btn" onClick={serchApp_func}><IconContext.Provider value={{ color: '#1384bf', size: '28px' }}><TbApps /></IconContext.Provider></button>
          }

          {serchFolder
            ? <button className="btn_off" onClick={serchFolder_func}><IconContext.Provider value={{ color: '#1384bf', size: '28px' }}><AiFillFolderOpen /></IconContext.Provider></button>
            : <button className="btn" onClick={serchFolder_func}><IconContext.Provider value={{ color: '#1384bf', size: '28px' }}><AiFillFolderOpen /></IconContext.Provider></button>
          }




        </div>
      </div>
    </div>

  )
}

export default Search