import React, { useState, useEffect, useContext } from 'react'
import dd from '../img/dd.png';
import AppBox from './AppBox';
import { ApiContext } from "../context/ApiContext";
import * as ReactDOM from 'react-dom';
import App from '../app/App';
import { IconContext } from 'react-icons' //IconContextをインポート
import { IoClose } from "react-icons/io5";





const ItemPage = () => {
  const { modalIsOpen, setModalIsOpen } = useContext(ApiContext);
  const { detailIsOpen, setDetailIsOpen } = useContext(ApiContext);
  const { item, setItem } = useContext(ApiContext);
  const { fileType, setFileType } = useContext(ApiContext);
  const { thum_img, setThum_img } = useContext(ApiContext);
  const { upDateFlg, setUpDateFlg, appMode, setAppMode, appContents, setName, setTime, setInterval_start, setInterval_end, setSpan_start, setSpan_end, setPlay_type } = useContext(ApiContext);



  const [ddStyle, setDdStyle] = useState('dd_area');
  const addItem = () => {
    setModalIsOpen(!modalIsOpen);
  }
  useEffect(() => {
    setAppMode(false);
  }, []);


  const handleMouseMove = (e) => {
    const dropZone = document.getElementById('dropZone');
    dropZone.addEventListener("dragover", dragover);
    dropZone.addEventListener("dragleave", dragleave);
    dropZone.addEventListener("drop", drop);

  };

  const dragover = (e) => {
    e.preventDefault();
    console.log('enter');
    setDdStyle('dd_area_on')
  }

  const dragleave = (e) => {
    e.preventDefault();
    console.log('enter');
    setDdStyle('dd_area')
  }

  const drop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    selectFile(files);
  }

  const fileFunc = () => {
    document.getElementById("selfile").click();
  }
  const fileChange = (e) => {

    selectFile(e.target.files);
  }

  const selectFile = (files) => {

    let file = files[0];

    let fileNameList = file.name.split(".");
    let fileType = fileNameList[fileNameList.length - 1];
    ///////初期値設定//////////////////////
    setName(file.name.split(".")[0]);
    setTime(15);
    setItem(file)
    setPlay_type("nomal");
    setInterval_start("");
    setInterval_end("");
    setSpan_start("");
    setSpan_end("");
    //////////////////////////////////
    //console.log(fileType);
    let fileMode = "";
    let preMode = ""

    if (fileType == "mp3" || fileType == "MP3") {
      fileMode = "mp3";


    } else {
      alert("ファイル形式を確認してください");
    }

    setFileType(fileMode);

    let reader = new FileReader()
    reader.onload = (e) => {
      setThum_img(reader.result)

    };
    reader.readAsDataURL(file)
    setUpDateFlg(false);
    setDetailIsOpen(!detailIsOpen);
    setModalIsOpen(!modalIsOpen);
  }

  return (
    <div>
      {

        (() => {
          if (appMode == false) {
            return (
              <div>
                <input name="file_1" onChange={(e) => fileChange(e)} multiple="" type="file" id="selfile" accept="*"></input>
                <div className='black_bg'>
                  <div className='item_box'>
                    <div className='btn_blue' id='maru_btn' onClick={addItem}>
                      <IconContext.Provider value={{ color: '#FFF', size: '50px', className: 'shdow' }}>
                        <IoClose />
                      </IconContext.Provider>
                    </div>
                    <div className='dd_box'>
                      <div className={ddStyle} id='dropZone' onLoad={handleMouseMove}>
                        <p>ファイルをドラック&ドロップ</p>
                        <img src={dd} alt="" />
                        <p>または</p>
                        <div className="file_btn" onClick={fileFunc}>
                          <p>ファイルを選択</p>
                        </div>
                      </div>

                    </div>
                    <AppBox />

                  </div>
                </div>

              </div>
            )
          } else {

            return (
              <App
                app_obj={appContents}
              />

            )

          }

        })()
      }
    </div>
  )
}

export default ItemPage
