import React, { useState,useEffect,useContext} from 'react';
import { ApiContext } from "../context/ApiContext";

const ItemOption = () => {
    const {span_start, setSpan_start} = useContext(ApiContext);
    const {span_end, setSpan_end} = useContext(ApiContext);
    const {interval_start, setInterval_start} = useContext(ApiContext);
    const {interval_end, setInterval_end} = useContext(ApiContext);
    const {play_type, setPlay_type} = useContext(ApiContext);
    


  const handleChange = (e) =>{
    setPlay_type(e.target.value);
   }
  return (
    <div className="item_option">
        <div id='item_box'>
            <p>予約配信:</p>
            <input id="input" size="30" type="date" className="from" name="bday" value={span_start} onChange={(event) => setSpan_start(event.target.value)}></input>
            <p>〜</p>
            <input id="input" size="30" type="date" className="to" name="bday" value={span_end} onChange={(event) => setSpan_end(event.target.value)}></input>
        </div>
        <div id='item_box'>
            <p>放映時間:</p>
            <input id="input" size="30" type="time" className="from_time" name="bday" value={interval_start} onChange={(event) => setInterval_start(event.target.value)}></input>
            <p>〜</p>
            <input id="input" size="30" type="time" className="to_time" name="bday" value={interval_end} onChange={(event) => setInterval_end(event.target.value)}></input>
        </div>
        <div id='item_box'>
        <p>放映帯タイプ:</p>
        <label>
        <input id="radio" type="radio" value="nomal" onChange={handleChange}checked={play_type === 'nomal'}/><p>通常</p>
        </label>
        <label>
        <input id="radio" type="radio" value="time" onChange={handleChange}checked={play_type === 'time'}/><p>時間帯独占</p>
        </label>
        <label>
        <input id="radio" type="radio" value="all" onChange={handleChange}checked={play_type === 'all'}/><p>完全独占</p>
        </label>



        </div>
    </div>
  )
}

export default ItemOption