import React, { useState, useEffect, useReducer, useContext } from 'react'
import { ApiContext } from "../../context/ApiContext";
import logo from '../../img/logo.png';





import { IconContext } from 'react-icons' //IconContextをインポート
import { FaUserCircle } from "react-icons/fa";
import { AiTwotoneSetting } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
//MdOutlineArrowDropDown


const NaviHeader = () => {

  const { subIsOpen, subPreIsOpen, project } = useContext(ApiContext);
  const openSub = () => {
    subPreIsOpen(!subIsOpen);
  }

  const closeSub = () => {
    if (subIsOpen) {
      subPreIsOpen(false);
    }

  }



  //window.addEventListener("click", closeSub);

  return (
    <div className='header'>
      <img className="logo" src={logo} alt="" />
      <div className='right'>

        <IconContext.Provider value={{ color: '#1384bf', size: '30px' }}><FaUserCircle /></IconContext.Provider>

        <p>{project.name}</p>
        <div className="strage_bar_bg">
          <div className="strage_bar">
          </div>
        </div>
        <p>0.3MB / 100MB</p>
        <button className="setting" onClick={openSub}>

          {subIsOpen
            ? <IconContext.Provider value={{ color: '#1384bf', size: '25px' }}><AiFillCloseCircle /></IconContext.Provider>
            : <IconContext.Provider value={{ color: '#1384bf', size: '25px' }}><AiTwotoneSetting /></IconContext.Provider>
          }

        </button>

      </div>



    </div>
  )
}

export default NaviHeader