import React, { useState,useEffect,useContext} from 'react'
import { ApiContext } from "../context/ApiContext";

import ItemPage from './ItemPage';
import ItemDetail from './ItemDetail';
import Calendar from './Calendar';
import Preview from './Preview';
import SubMenu from './SubMenu';
import Watch from './Watch';
import AI_watch from './AI_watch';
import AI_contents from './AI_contents';
import AI_flow from './AI_flow';


const Popup = () => {
  const { modalIsOpen, setModalIsOpen} = useContext(ApiContext);
  const { detailIsOpen, setDetailIsOpen} = useContext(ApiContext);
  const { calIsOpen, setCalIsOpen} = useContext(ApiContext);
  const { preIsOpen, setPreIsOpen} = useContext(ApiContext);
  const { subIsOpen, subPreIsOpen} = useContext(ApiContext);
  const { watchIsOpen, setWatchIsOpen} = useContext(ApiContext);
  const { AI_watchIsOpen, setAI_watchIsOpen} = useContext(ApiContext);
  const { AI_flowIsOpen, setAI_flowIsOpen} = useContext(ApiContext);
  const { AI_contentsIsOpen, setAI_contentsIsOpen} = useContext(ApiContext);
 
  return (
    <div>
     {modalIsOpen &&<ItemPage />}
     {detailIsOpen &&<ItemDetail />}
     {calIsOpen &&<Calendar />}
     {preIsOpen &&<Preview />}
     {subIsOpen &&<SubMenu />}
     {watchIsOpen &&<Watch />}
     {AI_watchIsOpen &&<AI_watch />}
     {AI_contentsIsOpen &&<AI_contents />}
     {AI_flowIsOpen &&<AI_flow />}
     </div>
  )
}



export default Popup
