import React, { useState,useEffect,useReducer,useContext } from 'react'
import { ApiContext } from "../context/ApiContext";

//onChange={(event) => setName(event.target.value)}
//value={}


const Group = () => {
    const {setGroupFlg,sortTimeline} = useContext(ApiContext);
    const {upDateFlg,postItem,setName,setItem,name,upDateItem} = useContext(ApiContext);
const ecs =()=>{
  
    sortTimeline();
    setGroupFlg(false);
}
const post =()=>{
  
  
  //alert(upDateFlg)
  setItem(null)

  if(upDateFlg){
    upDateItem();
  }else{
    
    postItem();
  }

  setGroupFlg(false);


}
  return (
    <div className="group_box">
        <input id="input" className="groupName" name="" value={name} type="text"  placeholder="グループ名" onChange={(event) => setName(event.target.value)}/>

        <div className='blue_btn' id='blue_btn' onClick={post}><p>グループ登録</p></div>
        <button className='white_btn' id='white_btn' onClick={ecs}>キャンセル</button>
    </div>
  )
}

export default Group