import React, { useState,useEffect,useContext} from 'react'
import { ApiContext } from "../context/ApiContext";
import { IconContext } from 'react-icons' //IconContextをインポート
import { IoClose } from  "react-icons/io5";
import { TbNetwork } from  "react-icons/tb";



const Watch = () => {
    const {setWatchIsOpen,timeLineList} = useContext(ApiContext);
    useEffect(() => {
       
        console.log(timeLineList)
    }, []);
        


    
    const ecs = () =>{
        setWatchIsOpen(false);
    }
  return (
    <div>

    <div className='black_bg'>
    <div className='cal_box'>
          <div className='main'>
            <div className='stb_tag'>
                <p className='timeline_id'>ID</p>
                <p className='name'>STB名称</p>
                <p className='stants'>状態</p>
                <p className='watch_time'>最終確認時間</p>
                <p className='command'>コマンド送信</p>
            </div>
         
          {timeLineList.map((timeline, index) => (
                 <div className='stb_block' key={'stb_id_'+index}>
                    <p className='timeline_id'>{timeline.id}</p>
                    <p className='name'>{timeline.name}</p>
                    <IconContext.Provider value={{ color: '#ff4800', size: '20px' }}>
                    <TbNetwork />
                    </IconContext.Provider>
                    <p className='stants'>オフライン</p>
                    <p className='watch_time'>2022.01.01 00:00</p>

                    <select className='command' name="command">
                        <option value=''>STB再起動</option>
                        <option value=''>画面取得</option>
                        <option value=''>モニターON</option>
                        <option value=''>モニターOFF</option>
                    </select>
                    <div className='btn_blue' id='command_btn'>送信</div>
                 </div>
                  
            ))} 
          
            
          </div>
          <div className='btn_blue' id='maru_btn' onClick={ecs}>
          <IconContext.Provider value={{ color: '#FFF', size: '50px',className:'shdow' }}>
              <IoClose />
            </IconContext.Provider>

            

          </div>
          

          </div>
    </div>
    </div>
  )
}

export default Watch